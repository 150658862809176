<template>
  <div>
    <div v-if="busy">
      <span>Retrieving data from server</span>
    </div>
    <div v-else>
      <div class="mb-3">
        <h4 style="font-weight: 600">
          {{
            user === null ? "No data" : user.first_name + "  " + user.last_name
          }}
        </h4>
        <div>
          <div>
            <span class="status" :class="kyc.status">{{ kyc.status }}</span>
          </div>
          <div v-if="kyc.status === 'pending'" class="flex gap-3 mt-3">
            <button
              class="warehauze-btn bg-green-600 text-white font-semibold"
              @click="changeKYCStatus('approved')"
            >
              Approve
            </button>
            <button
              class="warehauze-btn bg-red-600 text-white font-semibold"
              @click="changeKYCStatus('rejected')"
            >
              Decline
            </button>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div>
          <h5 class="mb-2" style="font-weight: 600">Information</h5>
          <table
            class="table table-striped table-borderless"
            style="width: 600px"
          >
            <tbody>
              <tr>
                <th scope="row">Beneficiary</th>
                <td class="text-capitalize">{{ kyc.beneficiary }}</td>
              </tr>
              <tr>
                <th scope="row">Business Registration</th>
                <td class="text-capitalize">
                  {{
                    kyc.is_registered === 0
                      ? "Business not registered"
                      : "Business Registered"
                  }}
                </td>
              </tr>
              <tr>
                <th scope="row">Company/Business Type</th>
                <td>{{ kyc.company_type }}</td>
              </tr>
              <tr>
                <th scope="row">Email</th>
                <td class="">{{ user === null ? "no data" : user.email }}</td>
              </tr>
              <tr>
                <th scope="row">Phone Number</th>
                <td>{{ user === null ? "no data" : user.phone_no }}</td>
              </tr>
              <tr>
                <th scope="row">Description</th>
                <td>{{ kyc.business_description }}</td>
              </tr>
              <tr v-if="kyc.status !== 'pending'">
                <th scope="row">Comment</th>
                <td>{{ kyc.admin_comment }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="bg-white mt-3">
        <h5 class="mb-2" style="font-weight: 600">Documents/Photos</h5>
        <div>
          <div>
            <label class="text-muted small" for="">Registration Document</label>
            <div>
              <div :class="file_extension"></div>
              <div class="d-flex" style="gap: 30px">
                <a
                  target="_blank"
                  :href="config.imgUrl + kyc.registration_document"
                  class="small"
                  >View Document</a
                >
                <a
                  :href="config.imgUrl + kyc.registration_document"
                  class="small"
                  download="Hello"
                  >Download Document</a
                >
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label class="text-muted small" for=""
              >Business/Company Photos</label
            >
            <div class="d-flex" style="gap: 30px">
              <div
                v-for="item in photos"
                :key="item.id"
                class="business--photo"
              >
                <a :href="config.imgUrl + item.photo">
                  <img :src="config.imgUrl + item.photo" alt="" />
                </a>
                <div class="etxt-center">
                  <a
                    target="_blank"
                    :href="config.imgUrl + item.photo"
                    class="small"
                    >View Photo</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Comment  -->
    <el-dialog
      :title="status + ' KYC'"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="30%"
    >
      <form action="" @submit.prevent="updateStatus">
        <div class="mb-3">
          <label for="" class="small text-muted">Enter Comment</label>
          <input type="text" v-model="admin_comment" />
        </div>
        <div>
          <button
            class="warehauze-btn text-white"
            :class="status === 'Approve' ? 'bg-green-600' : 'bg-red-600'"
          >
            {{ status }} KYC
          </button>
        </div>
      </form>
    </el-dialog>
    <!-- End of Add Comment  -->
  </div>
</template>

<script>
import config from "@/config/config";
// import { mapState } from "vuex";
import { timeStamp2 } from "@/plugins/filter";
export default {
  data() {
    return {
      id: this.$route.params.id,
      select: "",
      busy: false,
      kyc: {},
      timeStamp2,
      config,
      dialogVisible: false,
      status: "",
      visible: false,
      admin_comment: "",
      status_update: null,
    };
  },
  methods: {
    // ...mapActions("settings", ["getKYCByID", "updateKYCStatus"]),

    getKYCByID(value) {
      this.busy = true;
      this.$http
        .get(`admin/find-vendor-application/${value}`)
        .then((res) => {
          let resPayload = res.data.vendorApplication;
          this.kyc = resPayload;
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    changeKYCStatus(value) {
      this.dialogVisible = true;
      let status_to = value === "approved" ? "Approve" : "Decline";
      this.status = status_to;
      this.status_update = value;
    },

    handleClose() {
      this.admin_comment = "";
      this.dialogVisible = !this.dialogVisible;
    },

    updateStatus() {
      let payload = {
        status: this.status_update,
        admin_comment: this.admin_comment,
        id: this.id,
      };
      this.busy = true;
      this.$http
        .post(`admin/update-vendor-application/${this.id}`, payload)
        .then((res) => {
        this.getKYCByID(this.id);
          this.$toastify({
            text: `${res.data.message}`,
            className: "info",
            position: "center",
            style: {
              background: "#1e1e1e",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.busy = false;
        })
        .catch((err) => {
            this.busy = false;
          console.log(err);
          this.$toastify({
            text: `${err.data.message}`,
            className: "info",
            position: "center",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          
        });
      this.dialogVisible = false;
    },
  },

  beforeMount() {
    this.getKYCByID(this.id);
  },

  computed: {
    // ...mapState("settings", ["loading", "kyc"]),
    user() {
      return this.kyc.user;
    },
    file_extension() {
      return this.kyc.registration_document.split(".").pop();
    },
    photos() {
      let photos = [
        {
          id: 1,
          photo: this.kyc.photo_one,
        },
        {
          id: 2,
          photo: this.kyc.photo_two,
        },
        {
          id: 3,
          photo: this.kyc.photo_three,
        },
        {
          id: 4,
          photo: this.kyc.photo_four,
        },
      ];
      return photos;
    },
  },
};
</script>

<style>
.el-dropdown {
  vertical-align: top;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
