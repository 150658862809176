<template>
  <div>
    <div v-if="busy">
      <span>Retrieving data from server</span>
    </div>
    <div v-else>
        <div class="mb-3">
          <h4 style="font-weight: 600">
            {{
              user === null
                ? "no data"
                : user.first_name + "  " + user.last_name
            }}
          </h4>

          <div>
            <div>
              <span class="status" :class="application.status">{{ application.status }}</span>
            </div>
            <div
              v-if="application.status === 'pending'"
              class="flex gap-3 mt-3"
            >
              <button
                class="warehauze-btn bg-green-600 text-white font-semibold"
                @click="changeApplicationStatus('approved')"
              >
                Approve
              </button>
              <button
                class="warehauze-btn bg-red-600 text-white font-semibold"
                @click="changeApplicationStatus('rejected')"
              >
                Decline
              </button>
            </div>
          </div>
        </div>
        <div class="bg-white">
          <div>
            <h5 class="mb-2" style="font-weight: 600">Information</h5>
            <table
              class="table table-striped table-borderless"
              style="width: 600px"
            >
              <tbody>
                <tr>
                  <th scope="row">Beneficiary</th>
                  <td class="text-capitalize">{{ application.beneficiary }}</td>
                </tr>
                <tr>
                  <th scope="row">Business Address</th>
                  <td class="text-capitalize">
                    {{ application.business_address }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">RC/Registration Number</th>
                  <td>
                    {{
                      application.rc_number || application.registration_number
                    }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Tax Identification Number</th>
                  <td class="">{{ application.tax_number }}</td>
                </tr>
                <tr>
                  <th scope="row">Email</th>
                  <td class="">{{ user === null ? "no data" : user.email }}</td>
                </tr>
                <tr>
                  <th scope="row">Phone Number</th>
                  <td>{{ user === null ? "no data" : user.phone_no }}</td>
                </tr>
                <tr>
                  <th scope="row">Business Description</th>
                  <td>{{ application.business_description }}</td>
                </tr>
                <tr v-if="application.status !== 'pending'">
                  <th scope="row">Comment</th>
                  <td>{{ application.admin_comment }}</td>
                </tr>
                <tr>
                  <th scope="row">Registration Document</th>
                  <td>
                    <div class="d-flex align-items-center" style="gap: 10px">
                      <div
                        :class="file_extension"
                        style="width: 20px; height: 20px"
                      ></div>
                      <div class="d-flex" style="gap: 30px">
                        <a
                          target="_blank"
                          :href="
                            config.imgUrl + application.registration_document
                          "
                          class="small"
                          >View Document</a
                        >
                        <a
                          :href="
                            config.imgUrl + application.registration_document
                          "
                          class="small"
                          download="Hello"
                          >Download Document</a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>

    <!-- Add Comment  -->
    <el-dialog
      :title="status + ' Manufacturer Application'"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="30%"
    >
      <form action="" @submit.prevent="updateStatus">
        <div class="mb-3">
          <label for="" class="small text-muted">Enter Comment</label>
          <input type="text" v-model="admin_comment" />
        </div>
        <div>
          <button
            class="warehauze-btn text-white"
            :class="status === 'Approve' ? 'bg-green-600' : 'bg-red-600'"
          >
            {{ status }} Application
          </button>
        </div>
      </form>
    </el-dialog>
    <!-- End of Add Comment  -->
  </div>
</template>

<script>
import config from "@/config/config";
// import { mapState, mapActions } from "vuex";
import { timeStamp2 } from "@/plugins/filter";
export default {
  data() {
    return {
      id: this.$route.params.id,
      select: "",
      timeStamp2,
      config,
      dialogVisible: false,
      status: "",
      visible: false,
      admin_comment: "",
      status_update: null,
      busy: false,
      application: {},
    };
  },
  methods: {
    getApplicationByID(value) {
      this.busy = true;
      this.$http
        .get(`admin/find-manufacturer-application/${value}`)
        .then((res) => {
          let resPayload = res.data.manufacturerApplication;
          this.application = resPayload;
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    changeApplicationStatus(value) {
      this.dialogVisible = true;
      this.visible = !this.visible;
      let status_to = value === "approved" ? "Approve" : "Decline";
      this.status = status_to;
      this.status_update = value;
    },

    handleClose() {
      this.admin_comment = "";
      this.dialogVisible = !this.dialogVisible;
    },

    updateStatus() {
      let payload = {
        status: this.status_update,
        admin_comment: this.admin_comment,
        id: this.id,
      };
      this.busy = true;
      this.$http
        .post(`admin/update-manufacturer-application/${this.id}`, payload)
        .then((res) => {
          this.getApplicationByID(this.id);
          this.$toastify({
            text: `${res.data.message}`,
            className: "info",
            position: "center",
            style: {
              background: "#1e1e1e",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
          this.$toastify({
            text: `${err.data.message}`,
            className: "info",
            position: "center",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
        });
      this.dialogVisible = false;
    },
  },
  beforeMount() {
    this.getApplicationByID(this.id);
  },

  computed: {
    user() {
      return this.application.user;
    },
    file_extension() {
      return this.application.registration_document.split(".").pop();
    },
    //   photos(){
    //       let photos = [
    //           {
    //               id: 1,
    //               photo: this.kyc.photo_one
    //           },
    //           {
    //               id: 2,
    //               photo: this.kyc.photo_two
    //           },
    //           {
    //               id: 3,
    //               photo: this.kyc.photo_three
    //           },
    //           {
    //               id: 4,
    //               photo: this.kyc.photo_four
    //           }
    //       ]
    //       return photos
    //   }
  },
};
</script>

<style>
.el-dropdown {
  vertical-align: top;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
