<template>
  <div>
    <div class="grid grid-cols-3 gap-3 items-start">
      <div class="bg-white">
        <h6
          class="bg-primaryColor text-white px-4 py-2 uppercase font-semibold text-sm"
        >
          All Roles
        </h6>
        <div class="px-4 py-3">
          <span v-if="busy" class="flex flex-col gap-3">
            <b-skeleton
              width="100%"
              v-for="item in 5"
              :key="item"
              height="20px"
            ></b-skeleton>
          </span>
          <span v-else class="flex flex-col gap-3">
            <span
              :class="{ 'text-primaryColor': item.id === ID }"
              class="text-sm"
              role="button"
              v-for="(item, idx) in roles"
              @click="getData(item)"
              :key="idx"
              >{{ item?.name }}</span
            >
          </span>
        </div>
      </div>
      <div class="col-span-2 bg-white">
        <div>
          <span class="flex">
            <span
              class="block w-fit text-white px-4 py-2 uppercase font-semibold text-sm"
              v-for="(item, idx) in tabs"
              :key="idx"
              @click="activateTab(item, idx)"
              role="button"
              :class="[
                item.label === isActiveTab
                  ? 'bg-primaryColor text-white font-medium'
                  : 'bg-gray-400',
              ]"
            >
              {{ item.label.split("_").join(" ") }}
            </span>
          </span>
          <div class="">
            <component
              :is="tabs[activeTab].component"
              :roleDetails="roleDetails"
              :isLoading="isLoading"
              @refresh="getRolesInfo"
              :ID="ID"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserRoleManager from "@/components/Settings/UserRoleManager.vue";
import RoleManager from "@/components/Settings/RoleManager.vue";
export default {
  components: { UserRoleManager, RoleManager },
  data() {
    return {
      busy: false,
      isLoading: false,
      roles: [],
      roleDetails: {},
      ID: null,
      isAssignLoading: null,
      isActiveTab: "role_manager",
      activeTab: 0,
      tabs: [
        {
          label: "role_manager",
          component: RoleManager,
        },
        {
          label: "user_role_manager",
          component: UserRoleManager,
        },
      ],
    };
  },

  methods: {
    list() {
      this.busy = true;
      this.$http
        .get(`admin/roles`)
        .then((res) => {
          let resPayload = res.data;
          console.log(resPayload);
          this.roles = resPayload;
          this.ID = resPayload[0].id;
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    activateTab(e, i) {
      this.isActiveTab = e.label;
      this.activeTab = i;
    },

    getData(item) {
      this.ID = item.id;
    },

    getRolesInfo() {
      // if (!value) {
        this.isLoading = true;
      // }
      this.$http
        .get(`admin/roles/${this.ID}`)
        .then((res) => {
          let resPayload = res.data;
          console.log(resPayload);
          this.roleDetails = resPayload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  watch: {
    ID: {
      handler(val) {
        if (val) {
          this.getRolesInfo();
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.list();
  },
};
</script>

<style></style>
