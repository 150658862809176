var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('UtilsHeader',{attrs:{"title":`${_vm.shop.name}`,"data":_vm.displayData,"email":_vm.shop.user.email,"status":_vm.shop.status,"enable-action":true,"display-key":[
      'user_name',
      'type',
      'address',
      'phone_number',
      'city',
      'country',
    ]},scopedSlots:_vm._u([{key:"card-icons",fn:function(){return [_c('button',{staticClass:"px-4 py-2 text-xs text-white rounded-[5px] ml-7",class:[_vm.shop.status === 'activated' ? 'bg-primary' : 'bg-green-600'],on:{"click":function($event){$event.preventDefault();return _vm.changeStoreStatus(
            _vm.shop.status === 'activated' ? 'deactivated' : 'activated'
          )}}},[_vm._v(" "+_vm._s(_vm.shop.status === "activated" ? "Deactivate" : "Activate")+" ")])]},proxy:true}])}),_c('hr',{staticClass:"my-4"}),_c('div',[_c('h5',{staticClass:"font-bold"},[_vm._v("Products")]),_c('div',[_c('table-component',{attrs:{"items":_vm.shop_products,"busy":_vm.busy,"fields":_vm.fields}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }