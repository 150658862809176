import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'

import PrimeVue from 'primevue/config';
Vue.use(PrimeVue);
// import 'https://unpkg.com/primevue^2/resources/primevue.min.css'
// import "primevue/resources/primevue.css"
// import 'primevue/resources/themes/aura-light-green/theme.css'

import '@/assets/css/main.css'
// import '@/assets/css/media-query.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// to be added before mounting app
import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)

import { Icon } from "@iconify/vue2";
Vue.component("i-icon", Icon);

// Plugins
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import $http from '@/config/axios'
Vue.prototype.$http = $http

import config from "@/config/config"
Vue.prototype.config = config

import "toastify-js/src/toastify.css"
import toastify from 'toastify-js'
Vue.prototype.$toastify = toastify

// vue-sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2, {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
  reverseButtons: false,
  buttonsStyling: true,
  customClass: {
    confirmButton: "bg-green-600 text-white py-3",
    cancelButton: "bg-red-600 text-white py-3",
  },
});


import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);


// Filters 
Vue.filter("formatCurrency", function (value) {
  if (!value) return "";
  value = Number(value).toLocaleString("en-US", {
    style: "currency",
    currency: "NGN",
  });
  return value;
});

Vue.filter("formatDate", function (value) {
  if (!value) return "";
  let date = new Date(value);
  value = moment(String(date)).format("MMM DD, YYYY");
  return value;
});

import Dialog from 'primevue/dialog';
Vue.component("vueDialog", Dialog)

import Dropdown from 'primevue/dropdown';
Vue.component("vueDropdown", Dropdown)

import vPagination from '@/components/Utils/NewPagination.vue';
Vue.component("vPagination", vPagination)

import Editor from 'primevue/editor';
Vue.component("Editor", Editor)

import Chips from 'primevue/chips';
Vue.component("Chips", Chips)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')