<template>
  <div class="mt-3">
    <div class="d-flex" style="gap: 40px">
      <div class="w-100">
        <table class="table table-striped table-borderless">
          <tbody>
            <!-- {{ product }} -->
            <tr>
              <th scope="row">Price</th>
              <td>&#8358; {{ product.price.toLocaleString() }}</td>
            </tr>
            <tr>
              <th scope="row">Average Rating</th>
              <td>
                {{ product.avg_ratings === null ? "0" : product.avg_ratings }}
              </td>
            </tr>
            <tr>
              <th scope="row">Shop</th>
              <td class="text-capitalize">{{ product.shop.name }}</td>
            </tr>
            <tr>
              <th scope="row">Discount</th>
              <td class="flex items-center gap-2">
                &#8358;
                {{
                  product.discount !== null
                    ? product.discount.price.toLocaleString()
                    : "0"
                }}
                <span
                  class="cancelled bg-red-500 text-white p-1 rounded-sm text-xs"
                  >{{
                    product.discount !== null
                      ? product.discount.percentage + "% OFF"
                      : "0%"
                  }}</span
                >
              </td>
            </tr>
            <tr>
              <th scope="row">Categories</th>
              <td class="d-flex align-items-center" style="gap: 20px">
                {{ categories }}
              </td>
            </tr>

            <tr>
              <th scope="row">Sub-Categories</th>
              <td class="d-flex align-items-center" style="gap: 20px">
                {{ subCategories.length === 0 ? "---" : subCategories }}
              </td>
            </tr>
            <tr>
              <th scope="row">Tags</th>
              <td
                class="d-flex align-items-center text-capitalize"
                style="gap: 20px"
              >
                {{ tags }}
              </td>
            </tr>
            <tr>
              <th scope="row">Views</th>
              <td
                class="d-flex align-items-center text-capitalize"
                style="gap: 20px"
              >
                {{ product.views }}
              </td>
            </tr>
            <tr>
              <th scope="row">Measuring Unit</th>
              <td
                class="d-flex align-items-center text-capitalize"
                style="gap: 20px"
              >
                {{ product.measuring_unit }}
              </td>
            </tr>
            <tr>
              <th scope="row">Weight</th>
              <td
                class="d-flex align-items-center text-capitalize"
                style="gap: 20px"
              >
                {{ product.weight + "kg" }}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="max-h-[300px] overflow-y-auto">
          <h6 class="font-bold mb-2">Bulk Price details</h6>
          <table class="table table-striped table-borderless">
            <tbody>
              <tr v-for="item in product.price_ranges" :key="item.id">
                <th scope="row" style="width: 150px">
                  {{ `${item.min_quantity} ~ ${item.max_quantity} ${product.measuring_unit}` }}
                </th>
                <!-- <th scope="row" style="width: 150px">{{ item.lga }}</th> -->
                <td>&#8358; {{ item.price.toLocaleString() }}</td>
              </tr>
            </tbody>
          </table>
          <span class="text-sm" v-if="!product.price_ranges">No bulk price added for this product</span>
        </div>
      </div>

      <div class="w-100">
        <h6 class="font-bold">Shipping Details</h6>
        <hr class="my-3" />
        <div>
          <span
            v-if="shippingClass === null"
            class="block text-xs font-medium mb-3 text-red-600"
            >No Shipping Class added, select below to add a shipping class to
            this product</span
          >
          <div class="mb-3">
            <label for="" class="text-sm font-semibold"
              >Select Shipping Class</label
            >
            <select v-model="shipping_class" class="text-sm">
              <option value="" selected disabled>Select Shipping Class</option>
              <option
                v-for="(item, idx) in shipping_classes"
                :key="idx"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <button
            class="warehauze-btn warehauze-primary"
            @click="addShippingClass"
            v-if="!!shipping_class"
          >
            {{
              shippingClass === null
                ? "Add Shipping Class"
                : "Change Shipping Class"
            }}
          </button>
        </div>
        <div class="mt-3" v-if="shippingClass !== null">
          <div class="" v-if="shippingClass.shipping_zones === null">
            <el-alert
              title="No shipping zones added"
              type="error"
              :closable="false"
            >
            </el-alert>
          </div>
          <h6 class="flex gap-1 mb-3 text-sm items-center font-semibold">
            <span class="font-medium text-xs underline">Shipping Class:</span>
            {{ shippingClass.name }}
          </h6>
          <hr class="my-2" />
          <h6 class="mb-3 text-sm items-center font-semibold">
            Shipping Zones
          </h6>
          <div class="max-h-[300px] overflow-y-auto">
            <table class="table table-striped table-borderless">
              <tbody>
                <tr v-for="item in shippingClass.shipping_zones" :key="item.id">
                  <th scope="row" style="width: 150px">{{ item.name }}</th>
                  <th scope="row" style="width: 150px">{{ item.lga }}</th>
                  <td>&#8358; {{ item.price.toLocaleString() }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      shipping_class: "",
      shipping_classes: [],
    };
  },

  methods: {
    async getShippingClasses() {
      try {
        const res = await this.$http.get("all-shipping-classes");
        let resPayload = res.data.shipping_classes.data;
        this.shipping_classes = resPayload;
      } catch (error) {
        console.log(error);
      }
    },

    async addShippingClass() {
      let data = {
        shipping_class_id: this.shipping_class,
      };
      try {
        const res = await this.$http.post(
          `/admin/update-product-shipping-class/${this.product.id}`,
          data
        );
        this.$toastify({
          text: `${res.data.message}`,
          className: "info",
          position: "center",
          style: {
            background: "#1e1e1e",
            fontSize: "12px",
            borderRadius: "5px",
          },
        }).showToast();
        this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.$toastify({
          text: `${error.data.message}`,
          className: "info",
          position: "center",
          style: {
            background: "#1e1e1e",
            fontSize: "12px",
            borderRadius: "5px",
          },
        }).showToast();
      }
    },
  },

  beforeMount() {
    this.getShippingClasses();
  },

  computed: {
    shippingClass() {
      return this.product.shipping_class;
    },
    categories() {
      if (this.product.categories.length > 0) {
        var val = this.product.categories;
        let i;
        for (i = 0; i < val.length; i++) {
          var data = val[i].category_name;
          var result = [];
          result.push(data);
        }
        var product_categories = result.join(",");
        return product_categories;
      } else {
        return "This product has no category";
      }
    },

    tags() {
      if (this.product.tags.length > 0) {
        var val = this.product.tags;
        let i;
        for (i = 0; i < val.length; i++) {
          var data = val[i].name;
          var result = [];
          result.push(data);
        }
        var product_tags = result.join(",");
        return product_tags;
      } else {
        return "This product has no tag";
      }
    },
    subCategories() {
      if (this.product.sub_categories.length > 0) {
        var val = this.product.sub_categories;
        let i;
        for (i = 0; i < val.length; i++) {
          var data = val[i].name;
          var result = [];
          result.push(data);
        }
        var product_tags = result.join(",");
        return product_tags;
      } else {
        return "This product has no sub category";
      }
    },
  },
};
</script>
