<template>
  <div class="flex flex-col gap-5">
    <div class="flex justify-between">
      <div class="flex items-center gap-3">
        <span
          role="button"
          @click="$router.go(-1)"
          class="bg-gray-100 p-2 rounded-full"
        >
          <i-icon icon="ic:outline-arrow-back" />
        </span>
        <h4 class="text-lg font-semibold">Edit Mail</h4>
      </div>
      <div class="flex gap-3 items-start">
        <div class="center">
          <div class="form-input">
            <div class="preview">
              <img id="file-ip-1-preview" />
            </div>
            <label class="d-flex align-items-center" for="file-ip-1"
              ><i-icon class="" icon="iconamoon:attachment" />
            </label>
            <input
              type="file"
              id="file-ip-1"
              accept="image/*"
              @change="showPreview($event)"
            />
          </div>
        </div>
        <button
          @click="completeOperation('draft')"
          class="warehauze-btn flex items-center gap-1 border border-gray-300 text-gray-500"
        >
          <i-icon icon="ion:save-outline" />
          Save as Draft
        </button>
        <button
          @click="completeOperation('published')"
          class="warehauze-btn flex items-center gap-1 warehauze-primary"
        >
          <i-icon icon="prime:send" />
          Publish
        </button>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <div class="">
        <label for="" class="text-sm">Select Recipients From:</label>
        <select name="" id="" v-model="type" class="text-sm">
          <option value="">Enter Manually</option>
          <option value="primary">Primary</option>
          <option value="secondary">Secondary</option>
          <option value="subscribers">Subscribers</option>
        </select>
      </div>

      <div class="" v-if="type === 'secondary'">
        <label for="" class="text-sm">Select Mailing List</label>
        <select name="" v-model="category" id="" class="text-sm">
          <option value="" selected disabled>
            --Select Mailing List to send mail --
          </option>
          <option
            :value="item.name"
            v-for="(item, idx) in categories"
            :key="idx"
          >
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="" v-if="type === 'primary'">
        <label for="" class="text-sm">Select Group/Role</label>
        <select name="" id="" v-model="group" class="text-sm">
          <option value="" selected disabled>Select Group/Role</option>
          <option value="buyer">Buyer</option>
          <option value="vendor">Vendor</option>
          <option value="retailer">Retailer</option>
          <option value="wholesaler">Wholesaler</option>
        </select>
      </div>
    </div>
    <div>
      <label class="text-sm" for="">Name of Newsletter</label>
      <input
        type="text"
        placeholder="Enter name of newsletter"
        class="py-[12px] rounded-none text-sm border-gray-200 placeholder:text-sm"
        v-model="name"
      />
    </div>
    <div
      class="flex items-center gap-1 pl-2 border pr-3 py-1 border-gray-500"
      v-if="type === ''"
    >
      <span class="text-sm font-semibold text-gray-400">To:</span>
      <div
        class="chip-input flex items-center gap-1 flex-wrap w-full"
      >
        <div v-for="(chip, index) in chips" :key="index" class="chip w-max">
          <span class="text-xs">{{ chip }}</span>
          <span @click="removeChip(index)" class="close">&times;</span>
        </div>

        <input
          v-model.trim="newChip"
          @keydown.enter.prevent="addChip"
          @keydown.space.prevent="addChip"
          @keydown.backspace="removeLastChip"
          placeholder="Enter recipient"
          class="text-sm border-none w-max"
        />
      </div>
    </div>

    <div
      class="flex items-end gap-2 border pr-3 py-1 border-gray-500"
    >
      <input
        type="text"
        placeholder="Subject:"
        class="text-sm border-none"
        v-model="subject"
      />
    </div>

    <div>
      <Editor v-model="body" editorStyle="height: 350px;">
        <template #toolbar>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-link"></button>
            <button class="ql-align"></button>
            <button class="ql-align" value="right"></button>
            <button class="ql-align" value="center"></button>
            <button class="ql-align" value="justify"></button>
            <button class="ql-script" value="sub"></button>
            <button class="ql-script" value="super"></button>
            <select class="ql-header">
              <option selected></option>
              <option value="1"></option>
              <option value="2"></option>
              <option value="3"></option>
              <option value="4"></option>
              <option value="5"></option>
              <option value="6"></option>
            </select>
          </span>
        </template>
      </Editor>
      <span
        v-if="image"
        class="bg-gray-100 rounded-lg text-sm px-4 py-2 block mt-3 w-fit"
      >
        {{ image.name }} ({{ size }}kb)
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: "",
      value: null,
      assigned_name: "",
      image: null,
      subject: "",
      type: "",
      name: "",
      group: null,
      category: null,
      chips: [],
      newChip: "",
      categories: "",
      id: this.$route.params.id,
    };
  },

  methods: {
    showPreview(event) {
      var input = event.target;
      this.image = input.files[0];
      console.log(this.image);
      let getImageSize = this.image.size / 1024;
      this.size = Math.round(getImageSize);
    },
    async completeOperation(value) {
      console.log(this.chips);
      let toField = this.chips.length > 0 ? this.chips : null;
      const formData = new FormData();
      const formDataObject = {
        name: this.name,
        subject: this.subject,
        to: toField,
        body: this.body,
        status: value,
        type: this.type,
        group: this.group,
        category: this.category,
        attachment: this.image,
      };

      for (const key in formDataObject) {
        const value = formDataObject[key];

        // Check if the value is not null and not an array with null values
        if (
          value !== null &&
          !(Array.isArray(value) && value.some((val) => val === null))
        ) {
          // If it's not null or array with null values, add it to FormData
          if (Array.isArray(value)) {
            // Handle arrays by appending each non-null value separately
            value.forEach((val) => {
              if (val !== null) {
                formData.append(`${key}[]`, val); // Append with [] to simulate array-like behavior
              }
            });
          } else {
            // For non-array values, append directly
            formData.append(key, value);
          }
        }
      }
      try {
        let res = await this.$http.post("admin/newsletters/add", formData);
        console.log(res);
        this.$toastify({
          text: res.data.message,
          className: "info",
          style: {
            background: "#333",
            fontSize: "11px",
            borderRadius: "3px",
          },
        }).showToast();
        this.body = "";
        this.value = null;
        this.image = null;
        this.subject = "";
        this.type = "";
        this.name = "";
        this.group = null;
        this.category = null;
        this.chips = [];
      } catch (error) {
        console.log(error);
      }
    },
    addChip() {
      if (this.newChip.trim() !== "") {
        this.chips.push(this.newChip.trim());
        this.newChip = "";
      }
    },
    async categoriesList() {
      try {
        let res = await this.$http.get(`admin/mailing-categories`);
        console.log(res);
        this.categories = res.data.mailingCategories;
      } catch (error) {
        console.log(error);
      }
    },
    removeChip(index) {
      this.chips.splice(index, 1);
    },
    removeLastChip() {
      if (this.newChip === "" && this.chips.length > 0) {
        this.chips.pop();
      }
    },

    async getMail() {
      try {
        let res = await this.$http.get(`admin/newsletter/${this.id}`);
        console.log(res.data.newsletter, "details");
        let resData = res.data.newsletter
        this.body = resData.body;
        this.subject = resData.subject;
        this.type = resData.type;
        this.name = resData.name;
        this.group = resData.group;
        this.category = resData.category;
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.categoriesList();
    this.getMail();
  },
};
</script>

<style>
.center {
  /* height: 100%; */
}

.form-input input {
  display: none;
}

.form-input label {
  display: block;
  padding: 10px 14px;
  /* margin-bottom: 2rem; */
  text-align: center;
  border: 1px solid var(--gray-300);
  /* background: var(--accent-color); */
  color: var(--gray-500) !important;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

/* Styles for chips */
.chip {
  /* display: inline-block; */
  padding: 0.25rem 0.5rem;
  /* margin: 0.25rem; */
  background-color: #f0f0f0;
  border-radius: 16px;
  cursor: pointer;
}

/* Close button style */
.close {
  margin-left: 0.5rem;
  color: red;
  font-size: 14px;
  cursor: pointer;
}

/* Input field style */
input {
  border: none;
  outline: none;
  padding: 0.25rem 0.5rem;
  border-radius: 16px;
  background-color: #f9f9f9;
  /* width: 100%; */
}
</style>
