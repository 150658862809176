<template>
  <div>
    <div class="">
      <table-component
        :items="items"
        :busy="busy"
        :disableEditAction="false"
        :disableDeleteAction="false"
        :fields="fields"
        @view="getRecord($event, 'view')"
        @delete="deleteRecord"
        @edit="getRecord($event, 'edit')"
        :paginate="false"
        @view_subcategories="getSubCategories"
        btnValue="view_subcategories"
      >
        <template #button>
          <button
            @click="dialogVisible = !dialogVisible"
            class="warehauze-btn warehauze-primary"
          >
            Add Category
          </button>
        </template>
      </table-component>
    </div>

    <!-- Sub Categories  -->
    <el-drawer
      :title="categoryDetails.category_name"
      :visible.sync="drawer"
      direction="rtl"
      size="40%"
    >
      <div class="p-4">
        <h5>Sub Categories</h5>
        <table-component
          :items="categoryDetails.sub_categories"
          :busy="busy"
          :disableEditAction="false"
          :disableDeleteAction="false"
          :fields="subCatFields"
          @view="getSubRecord($event, 'view')"
          @delete="deleteSubRecord"
          @edit="getSubRecord($event, 'edit')"
          :paginate="false"
        >
          <template #button>
            <button
              @click="subDialogVisible = !subDialogVisible"
              class="warehauze-btn warehauze-primary"
            >
              Add SubCategory
            </button>
          </template>
        </table-component>
      </div>
    </el-drawer>

    <!-- Modal to Add or Edit Categories -->
    <div>
      <Category-modal
        :visible="dialogVisible"
        @toggle="closeModal"
        @done="done"
        :title="title"
        :editMode="editMode"
        :viewMode="viewMode"
        :data="item"
      />
    </div>

    <!-- Modal to Add or Edit SubCategories -->
    <div>
      <SubCategory
        :visible="subDialogVisible"
        @toggle="closeSubModal"
        @done="subDone"
        :title="subTitle"
        :editMode="subEditMode"
        :viewMode="subViewMode"
        :data="subItem"
        :item="categoryDetails"
      />
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import CategoryModal from "../Modals/Settings/CategoryModal.vue";
import SubCategory from "../Modals/Settings/SubCategory.vue";
export default {
  components: { TableComponent, CategoryModal, SubCategory },
  data() {
    return {
      items: [],
      item: {},
      subItem: {},
      drawer: false,
      title: "",
      subTitle: "",
      busy: false,
      dialogVisible: false,
      subDialogVisible: false,
      categoryDetails: {},
      editMode: false,
      subEditMode: false,
      viewMode: false,
      subViewMode: false,
      fields: [
        {
          key: "table_img",
          label: "",
        },

        {
          key: "category_name",
          label: "Category Name",
        },
        {
          key: "slug",
          label: "Slug",
        },

        {
          key: "created_at",
          label: "Date Created",
        },

        {
          key: "actions",
          label: "",
        },

        {
          key: "button",
          label: "",
        },
      ],
      subCatFields: [
        {
          key: "table_img",
          label: "",
        },

        {
          key: "sub_category_name",
          label: "Name",
        },
        {
          key: "slug",
          label: "Slug",
        },

        // {
        //   key: "created_at",
        //   label: "Date Created",
        // },

        {
          key: "actions",
          label: "",
        },
      ],
    };
  },

  methods: {
    getData() {
      this.busy = true;
      this.$http
        .get("all-categories")
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload.categories;
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    getRecord(e, value) {
      this.item = e;
      console.log(e, "hhyhe");
      if (value === "edit") {
        this.editMode = true;
        this.toggleModal();
      } else {
        this.viewMode = true;
        this.toggleModal();
      }
    },

    getSubRecord(e, value) {
      this.subItem = e;
      console.log(e, "hhyhe");
      if (value === "edit") {
        this.subEditMode = true;
        this.subToggleModal();
      } else {
        this.subViewMode = true;
        this.subToggleModal();
      }
    },

    getSubCategories(value) {
      this.$http
        .get(`show-category/${value.slug}`)
        .then((res) => {
          let resPayload = res.data;
          this.drawer = true;
          this.categoryDetails = resPayload.category;
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    closeModal() {
      this.item = {};
      this.dialogVisible = !this.dialogVisible;
      this.editMode = false;
      this.viewMode = false;
    },

    closeSubModal() {
      this.subItem = {};
      this.subDialogVisible = !this.subDialogVisible;
      this.subEditMode = false;
      this.subViewMode = false;
    },

    complete(value) {
      this.$http
        .post(`/admin/delete-category/${value.id}`)
        .then((res) => {
          this.$swal.fire("Successful!", `${res.data.message}`, "success");
          this.getData();
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteRecord(value) {
      this.$swal
        .fire({
          title: "Continue?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.complete(value);
          }
        });
    },

    subComplete(value) {
      this.$http
        .post(`/admin/delete-subcategory/${value.id}`)
        .then((res) => {
          this.$swal.fire("Successful!", `${res.data.message}`, "success");
          this.getData();
          this.getSubCategories(this.categoryDetails)
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteSubRecord(value) {
      this.$swal
        .fire({
          title: "Continue?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.subComplete(value);
          }
        });
    },

    toggleModal() {
      if (this.editMode) {
        this.title = "Edit";
        this.dialogVisible = !this.dialogVisible;
      } else if (this.viewMode) {
        this.title = "View";
        this.dialogVisible = !this.dialogVisible;
      } else {
        this.title = "Create New";
        this.dialogVisible = !this.dialogVisible;
      }
    },

    subToggleModal() {
      if (this.subEditMode) {
        this.subTitle = "Edit";
        this.subDialogVisible = !this.subDialogVisible;
      } else if (this.subViewMode) {
        this.subTitle = "View";
        this.subDialogVisible = !this.subDialogVisible;
      } else {
        this.subTitle = "Create New";
        this.subDialogVisible = !this.subDialogVisible;
      }
    },

    done() {
      this.closeModal();
      this.getData();
    },

    subDone() {
      this.closeSubModal();
      // this.getData();
      this.getSubCategories(this.categoryDetails)
    },

    viewRecord(value) {
      this.$router.push(`store/${value.id}`);
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style></style>
