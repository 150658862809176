<template>
  <div>
    <table-component
      :items="products"
      :busy="busy"
      :disableEditAction="true"
      :disableDeleteAction="true"
      :fields="fields"
      @view="view"
      :meta="meta"
      @next="list($event)"
    >
    </table-component>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";

export default {
  components: { TableComponent },
  data() {
    return {
      products: [],
      busy: false,
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "created_at",
          label: "Date Created",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      meta: {},
    };
  },

  methods: {
    list(page = 1) {
      this.busy = true;
      this.$http
        .get(`admin/get-shops?page=${page}`)
        .then((res) => {
          let resPayload = res.data;
          this.products = resPayload.data;
          var meta = resPayload;
          this.meta = meta;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          return err;
        });
    },

    deleteProduct(value) {
      this.$http
        .post(`admin/delete-product/${value.id}`)
        .then((res) => {
          this.$swal.fire(
            "Deleted!",
            "Product deleted succesfully.",
            "success"
          );
          this.list();
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteRecord(value) {
      this.$swal
        .fire({
          title: "Continue?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          console.log(result, "kkk");
          if (result.isConfirmed) {
            this.deleteProduct(value);
          }
        });
    },

    view(value) {
      this.$router.push(`store/${value.id}`);
    },
  },

  mounted() {
    this.list();
  },
};
</script>

<style></style>
