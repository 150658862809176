<template>
  <div>
    <div class="grid grid-cols-2 gap-3 items-start">
      <div class="bg-white">
        <h6
          class="bg-primaryColor text-white px-4 py-2 uppercase font-semibold text-sm"
        >
          All Modules
        </h6>
        <div class="px-4 py-3">
          <span v-if="busy" class="flex flex-col gap-3">
            <b-skeleton
              width="100%"
              v-for="item in 5"
              :key="item"
              height="20px"
            ></b-skeleton>
          </span>
          <span v-else class="flex flex-col gap-3">
            <div class="flex gap-2">
              <input
                type="text"
                v-model="m_name"
                placeholder="Enter Module Name"
                class="text-xs input"
              />
              <button
                @click="createModule"
                class="warehauze-btn warehauze-primary w-fit block whitespace-nowrap ml-auto"
              >
                Add Module
              </button>
            </div>
            <span
              :class="{ 'text-primaryColor': item.id === ID }"
              class="text-sm flex items-center justify-between"
              role="button"
              v-for="(item, idx) in modules"
              :key="idx"
            >
              <span @click="setID(item)">
                {{ item?.name }}
              </span>

              <span
                class="text-red-600"
                role="button"
                @click="removeModule(item)"
              >
                <i-icon icon="fluent:delete-16-filled" />
              </span>
            </span>
          </span>
        </div>
      </div>
      <div class="bg-white">
        <div>
          <span class="flex">
            <span
              class="block w-full text-white px-4 py-2 uppercase font-semibold text-sm bg-primaryColor text-white"
            >
              Sub Modules
            </span>
          </span>
          <div class="px-4 py-3">
            <span class="flex flex-col gap-3">
              <div class="flex gap-2">
                <input
                  type="text"
                  v-model="sm_name"
                  placeholder="Enter Module Name"
                  class="text-xs input"
                />
                <button
                  class="warehauze-btn warehauze-primary w-fit block whitespace-nowrap ml-auto"
                  @click="createSubModule"
                >
                  Add Sub Module
                </button>
              </div>
              <span
                class="text-sm flex justify-between items-center"
                role="button"
                v-for="(item, idx) in sub_modules"
                :key="idx"
                >{{ item?.name }}
                <span
                  class="text-red-600"
                  role="button"
                  @click="removeSubModule(item)"
                >
                  <i-icon icon="fluent:delete-16-filled" />
                </span>
              </span>
              <span
                v-if="!isLoading && sub_modules.length === 0"
                class="block mx-auto block flex flex-col gap-1 items-center"
              >
                <i-icon icon="material-symbols:view-module" class="text-4xl" />
                <span class="text-sm">No sub modules found</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      busy: false,
      isLoading: false,
      ID: "",
      modules: [],
      sub_modules: [],
      m_name: "",
      sm_name: "",
    };
  },

  methods: {
    list() {
      this.busy = true;
      this.$http
        .get(`admin/system-modules`)
        .then((res) => {
          let resPayload = res.data;
          console.log(resPayload);
          this.ID = resPayload[0].id;
          this.modules = resPayload;
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    setID(v) {
      this.ID = v.id;
    },

    getSubModules() {
      this.isLoading = true
      this.$http
        .get(`admin/sub-system-modules/${this.ID}/system-module`)
        .then((res) => {
          let resPayload = res.data;
          console.log(resPayload);
          this.sub_modules = resPayload;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    createModule() {
      let payload = {
        name: this.m_name,
      };
      this.$http
        .post(`admin/system-modules`, payload)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.list();
          this.m_name = "";
        });
    },

    createSubModule() {
      let payload = {
        name: this.sm_name,
        system_module_id: this.ID,
      };
      this.$http
        .post(`admin/sub-system-modules`, payload)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.getSubModules();
          this.sm_name = "";
        });
    },

    removeModule(v) {
      this.$http
        .post(`admin/system-modules/${v.id}/delete`)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.list();
        });
    },

    removeSubModule(v) {
      this.$http
        .post(`admin/sub-system-modules/${v.id}/delete`)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.getSubModules();
        });
    }
  },

  watch: {
    ID: {
      handler(oldVal, newVal) {
        if (oldVal !== newVal) {
          this.getSubModules();
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.list();
  },
};
</script>

<style></style>
