<template>
  <div>
    <div class="input-field">
      <input
        type="text"
        name="search"
        id="searchFilter"
        v-model="search"
        placeholder="Search by email"
        @keyup="$emit('setValue', search)"
      />
      <span class="password-iccon" role="button">
        <i-icon icon="gg:search" class="form-icon" />
      </span>
    </div>
  </div>
</template>

<script>
// import { debounce } from "lodash";
export default {
  data() {
    return {
      search: "",
    };
  },

  watch: {
    
  },
};
</script>

<style></style>
