<template>
  <div>
    <div class="bg-gray-100 flex gap-2 mb-6 rounded-sm p-2">
      <span
        role="button"
        v-for="(item, idx) in tabs"
        :key="idx"
        @click="activateTab(item, idx)"
        :class="[
          item.name === active
            ? 'bg-primaryColor text-white font-semibold'
            : 'bg-white',
        ]"
        class="font-medium text-[12px] py-[8px] px-4 block rounded-sm"
      >
        {{ item.title }}
      </span>
    </div>
    <div>
      <table-component
        :items="items"
        :fields="fields"
        :disableEditAction="true"
        :disableDeleteAction="true"
        @view="view($event)"
        @next="pageFunc($event)"
        :meta="meta"
        :busy="busy"
      >
      <template #search>
        <div>
          <search-filter @setValue="setValue"/>
        </div>
      </template>
      </table-component>
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import SearchFilter from '@/components/filters/searchFilter.vue';
import { debounce } from 'lodash'
export default {
  components: {
    TableComponent,
    SearchFilter,
  },
  data() {
    return {
      items: [],
      visible: false,
      role: "",
      fields: [
        {
          key: "first_name",
          label: "First Name",
        },
        {
          key: "last_name",
          label: "Last Name",
        },
        {
          key: "role",
          label: "Role",
        },
        {
          key: "type",
          label: "Type",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      meta: {},
      tabs: [
        {
          title: "All Users",
          name: "all",
          status: "",
        },
        {
          title: "Customers",
          name: "Customers",
          status: "buyer",
        },
        {
          title: "Vendors",
          name: "Vendors",
          status: "vendor",
        },
        {
          title: "Admins",
          name: "Admins",
          status: "admin",
        },
      ],
      active: "all",
      activeName: "All Users",
      activeTab: 0,
      busy: false,
      filterQuery: {
        role: "",
        page: 1,
        email: ''
      }
    };
  },

  methods: {
    activateTab(item, idx) {
      this.active = item.name;
      this.activeName = item.title;
      this.activeTab = idx;
      this.filterQuery.role = item.status;
    },

    pageFunc(e){
      this.filterQuery.page = e
    },

    // Method to convert the filters object into a query string
    buildQuery(filters) {
      return Object.keys(filters)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filters[key]))
        .join('&');
    },

    getUsers() {
      this.busy = true;
      const query = this.buildQuery(this.filterQuery);
      this.$http
        .get(`admin/get-users?${query}`)
        // .get(`admin/get-users?role=${this.role}&page=${page}`)
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload.data;
          console.log(resPayload);
          this.busy = false;
          this.meta = resPayload
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    changePage(value) {
      console.log(value, "kkkkk");
    },

    setValue(e) {
      this.filterQuery.email = e
    },

    cancel() {
      this.visible = false;
    },

    view(value) {
      this.$router.push(`/inventory/user/${value.id}`);
    },

    ok(value) {
      console.log(value);
    },
  },

  beforeMount() {
    this.getUsers();
  },

  watch: {
    filterQuery: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getUsers();
        }
      },
      immediate: true,
      deep: true
    },

    "filterQuery.email": {
      handler: debounce(function () {
        this.getUsers();
      }, 500),
    },

    "filterQuery.role": {
      handler: debounce(function () {
        this.getUsers();
      }, 200),
    },
    
    "filterQuery.page": {
      handler: debounce(function () {
        this.getUsers();
      }, 200),
    },
  },

  mounted() {},
};
</script>

<style></style>
