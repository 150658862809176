<template>
  <div>
    <div v-if="busy || isLoading" class="flex flex-col gap-3 mt-2">
      <b-skeleton width="100%" height="30px"></b-skeleton>

      <div class="px-4">
        <b-skeleton width="100%" height="30px"></b-skeleton>
        <span class="mt-2">
          <b-skeleton
            width="100%"
            v-for="item in 4"
            :key="item"
            height="20px"
          ></b-skeleton>
        </span>
      </div>
    </div>
    <div class="mt-2" v-else>
      <div v-for="(item, idx) in roleInfo.perms" :key="idx">
        <span class="bg-gray-100 px-4 py-2 font-semibold text-sm block">
          {{ item.name }}
        </span>
        <div class="px-4 py-2">
          <span
            class="flex flex-col gap-2 mt-2"
            v-for="(it, idx) in item.sub_modules"
            :key="idx"
          >
            <span class="bg-gray-100 px-2 py-2 text-sm block">
              {{ it.name }}
            </span>
            <span
              class="block text-sm capitalize flex items-center justify-between"
              v-for="(value, idx) in it.permissions"
              :key="idx"
            >
              <span class="flex gap-[10px]">
                <input type="checkbox" class="w-fit" v-model="value.access" />
                <span class="text-sm">{{
                  value.name.split("_").join(" ")
                }}</span>
              </span>
              <span class="flex gap-2 items-center">
                <!-- <span
                          class="lowercase"
                          :class="[value.access ? 'assigned' : 'unassigned']"
                        >
                          {{ value.access ? "acceess granted" : "access denied" }}
                        </span> -->
                <button
                  class="warehauze-btn py-2 px-2 flex justify-center font-medium items-center gap-1"
                  @click="assignFunction(value)"
                  :class="[
                    !value.access
                      ? 'bg-green-600 text-white'
                      : isAssignLoading === value.name
                      ? 'bg-gray-400'
                      : 'warehauze-primary',
                  ]"
                  :disabled="isAssignLoading === value.name"
                >
                  <i-icon
                    v-if="isAssignLoading === value.name"
                    icon="eos-icons:loading"
                    class="text-white text-[14px]"
                  />
                  {{ value.access ? "Remove Access" : "Grant Access" }}
                </button>
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    roleDetails: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    ID: null,
  },
  data() {
    return {
      busy: false,
      roles: [],
      isAssignLoading: null,
      roleInfo: {}
    };
  },

  methods: {
    assignFunction(value) {
      let payload = {
        name: value.name,
        access: value.access ? 0 : 1,
      };
      console.log(value, payload);
      this.isAssignLoading = value.name;
      this.$http
        .post(`admin/roles/${this.ID}/assign-permission`, payload)
        .then((res) => {
          this.$emit('refresh')
          console.log(res);
          let info = value.access ? 'Permission Removed' : 'Permission Granted'
          this.$swal.fire(
            "Done!",
            info,
            "success"
          );
          return res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isAssignLoading = null;
        });
    },
  },

  watch: {
    roleDetails: {
      handler(val) {
        if(val) {
          this.roleInfo = val
        }
      }
    }
  },

  mounted() {
  },
};
</script>

<style></style>
