<template>
  <div>
    <UtilsHeader
      :title="`${shop.name}`"
      :data="displayData"
      :email="shop.user.email"
      :status="shop.status"
      :enable-action="true"
      :display-key="[
        'user_name',
        'type',
        'address',
        'phone_number',
        'city',
        'country',
      ]"
    >
      <template #card-icons>
        <button
          class="px-4 py-2 text-xs text-white rounded-[5px] ml-7"
          :class="[shop.status === 'activated' ? 'bg-primary' : 'bg-green-600']"
          @click.prevent="
            changeStoreStatus(
              shop.status === 'activated' ? 'deactivated' : 'activated'
            )
          "
        >
          {{
            shop.status === "activated" ? "Deactivate" : "Activate"
          }}
        </button>
      </template>
    </UtilsHeader>

    <hr class="my-4" />

    <div>
      <h5 class="font-bold">Products</h5>
      <div>
        <table-component :items="shop_products" :busy="busy" :fields="fields" />
      </div>
    </div>
  </div>
</template>

<script>
import UtilsHeader from "@/components/Utils/UserHeader.vue";
import TableComponent from "@/components/TableComponent.vue";
export default {
  components: {
    UtilsHeader,
    TableComponent,
  },
  data() {
    return {
      shop: {},
      shop_products: [],
      displayData: {
        user_name: "",
        type: "",
        address: "",
        phone_number: "",
        city: "",
        country: "",
      },
      fields: [
        {
          key: "app_image",
          label: "",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "price",
          label: "Price",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "";
          },
        },
        {
          key: "availability",
          label: "Availability",
        },
        {
          key: "created_at",
          label: "Date Created",
        },
        {
          key: "status",
          label: "Status",
        },
      ],
      id: this.$route.params.id
    };
  },

  methods: {
    getStore(value) {
      this.busy = true;
      this.$http
        .get(`admin/find-shop/${value}`)
        .then((res) => {
          let resPayload = res.data;
          this.shop = resPayload.shop;
          this.shop_products = resPayload.shop.products;
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    changeStatus(value) {
      console.log(value);
      let payload  = {
        status: value
      }
      this.$http
        .post(`admin/edit-shop-status/${this.id}`, payload )
        .then((res) => {
          console.log(res.data.message);
          this.$toastify({
            text: `Product Status updated`,
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "3px",
            },
          }).showToast();
          this.getStore(this.id);
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
      
    },

    changeStoreStatus(value) {
      this.$swal
        .fire({
          title: "Update Store Status?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Continue",
        })
        .then((result) => {
          console.log(result, "kkk");
          if (result.isConfirmed) {
            this.changeStatus(value);
          }
        });
    },
  },

  watch: {
    shop: {
      handler(val) {
        if (val.user) {
          this.displayData = {
            user_name: val.user.first_name + " " + val.user.last_name,
            type: val.user.type,
            address: val.user.address,
            phone_number: val.user.phone_no,
            city: val.user.city,
            country: val.user.country,
          };
        }
      },
      immediate: true,
    },
  },

  beforeMount() {
    this.getStore(this.id);
  },
};
</script>

<style></style>
