var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex justify-between items-center mb-4"},[_vm._m(0),_c('button',{staticClass:"warehauze-btn warehauze-primary",on:{"click":_vm.openModal}},[_vm._v(" Generate Coupon ")])]),_c('div',[_c('table-component',{attrs:{"items":_vm.items,"fields":_vm.fields,"busy":_vm.busy,"meta":_vm.meta,"deleteTitle":"You are about to disable this coupon code!, this action is irreversible"},on:{"delete":_vm.disable,"view":_vm.view,"next":function($event){return _vm.filter($event)},"edit":_vm.edit}})],1),_c('view-receipts',{attrs:{"visible":_vm.dialogVisible,"date":_vm.item.created_at,"title":"Code Details","displayData":_vm.displayData,"hasAction":false,"display-key":[
      'code',
      'discount_percentage',
      'start_date',
      'end_date',
      'shop',
      'status',
    ]},on:{"toggle":_vm.close,"payoutsFunc":function($event){return _vm.payoutsFunc($event)},"copy":_vm.copyAccountNumber}}),_c('create-promo-code',{attrs:{"visible":_vm.visibleCreate,"data":_vm.item,"editMode":_vm.editMode},on:{"done":_vm.filter,"toggle":function($event){_vm.visibleCreate = false}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',{staticClass:"font-bold"},[_vm._v("Coupons")])])
}]

export { render, staticRenderFns }