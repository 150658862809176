<template>
  <div>
    <div class="flex justify-between items-center mb-4">
      <div>
        <input
          type="text"
          v-model="search"
          placeholder="Search by reference number"
          class="input placeholder:text-xs text-sm"
        />
      </div>
      <filter-tabs :tabs="tabs" @filter-changed="filter($event)" />
    </div>
    <div>
      <table-component
        :items="items"
        :disableEditAction="true"
        :fields="fields"
        :busy="busy"
        @view="view"
        :meta="meta"
        @next="filter($event)"
        @delete="deleteRecord"
      />
    </div>
  </div>
</template>

<script>
import FilterTabs from "@/components/Utils/FilterTabs.vue";
import TableComponent from "@/components/TableComponent.vue";
import { debounce } from "lodash";
export default {
  components: { FilterTabs, TableComponent },
  data() {
    return {
      tabs: [
        {
          title: "All",
          label: "",
        },
        {
          title: "Today",
          label: "today",
        },
        {
          title: "Last Week",
          label: "7-days",
        },
        {
          title: "Last Month",
          label: "1-month",
        },
        {
          title: "Last Year",
          label: "1-year",
        },
      ],
      items: [],
      fields: [
        {
          key: "created_at",
          label: "Date",
        },
        {
          key: "user",
          label: "Customer",
          formatter: (val) => {
            return val ? val.first_name + " " + val.last_name : "";
          },
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "total_quantity",
          label: "Quantity",
        },

        {
          key: "total_amount",
          label: "Amount",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "";
          },
        },

        {
          key: "actions",
          label: "",
        },
      ],
      busy: false,
      dialogVisible: false,
      displayData: {
        customer_name: "",
        channel: "",
        amount: "",
        reference_number: "",
        transaction_reference: "",
        status: "",
        description: "",
      },
      meta: {},
      item: {},
      search: null,
    };
  },

  methods: {
    filter(e, page = 1) {
      this.busy = true;
      let params = {
        page: page,
        time: e,
        ref_no: this.search,
      };
      this.$http
        .get(`admin/all-orders`, { params })
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload.data;
          console.log(resPayload);
          this.meta = resPayload;
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    deleteRecord(e) {
      this.$http
        .post(`admin/delete-order/${e.id}`)
        .then((res) => {
          this.filter();
          return res;
        })
        .catch((err) => {
          return err;
        });
    },

    view(e) {
      this.$router.push(`/finance/order/${e.id}`);
    },

    close() {
      this.dialogVisible = !this.dialogVisible;
    },
  },

  beforeMount() {
    let value = "";
    this.filter(value);
  },

  watch: {
    item: {
      handler(val) {
        if (val.user) {
          this.displayData = {
            customer_name: val.name,
            channel: val.channel,
            amount: `${Number(val.amount).toLocaleString()} ${val.currency}`,
            reference_number: val.ref_no,
            transaction_reference: val.tx_ref,
            status: val.status,
            description: val.description,
          };
        }
      },
      immediate: true,
    },

    search: {
      handler: debounce(function () {
        this.filter();
      }, 500),
    },
  },
};
</script>

<style></style>
