<template>
  <div>
    <div class="">
      <table-component
        :items="items"
        :busy="busy"
        :disableEditAction="true"
        :disableDeleteAction="false"
        :fields="fields"
        @view="getRecord($event, 'view')"
        @delete="deleteRecord"
        :paginate="false"
      >
        <template #button>
          <button
            @click="dialogVisible = !dialogVisible"
            class="warehauze-btn warehauze-primary"
          >
            Add Shipping Class
          </button>
        </template>
      </table-component>
    </div>

    <!-- Details  -->
    <el-drawer
      :title="details.name"
      :visible.sync="drawer"
      direction="rtl"
      size="40%"
    >
      <div class="p-4">
        <h5>Shipping Zones</h5>
        <table-component
          :items="details.shipping_zones"
          :busy="busy"
          :disableEditAction="true"
          :disableDeleteAction="false"
          :fields="subFields"
          @view="getSubRecord($event, 'view')"
          @delete="deleteSubRecord"
          @edit="getSubRecord($event, 'edit')"
          :paginate="false"
        >
          <template #button>
            <button
              @click="subToggleModal"
              class="warehauze-btn warehauze-primary"
            >
              Add Shipping Zone
            </button>
          </template>
        </table-component>
      </div>
    </el-drawer>

    <!-- Modal to Add or Edit Categories -->
    <div>
      <ShippingClass
        :visible="dialogVisible"
        @toggle="closeModal"
        @done="done"
        :title="title"
        :editMode="editMode"
        :viewMode="viewMode"
        :data="item"
      />
    </div>

    <!-- Modal to Add or Edit SubCategories -->
    <div>
      <ShippingZone
        :visible="subDialogVisible"
        @toggle="closeSubModal"
        @done="subDone"
        :title="subTitle"
        :editMode="subEditMode"
        :viewMode="subViewMode"
        :data="subItem"
        :item="details"
      />
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import ShippingClass from "../Modals/Settings/ShippingClass.vue";
import ShippingZone from "../Modals/Settings/ShippingZone.vue";
export default {
  components: { TableComponent, ShippingClass, ShippingZone },
  data() {
    return {
      items: [],
      item: {},
      subItem: {},
      drawer: false,
      title: "",
      subTitle: "",
      busy: false,
      dialogVisible: false,
      subDialogVisible: false,
      details: {},
      editMode: false,
      subEditMode: false,
      viewMode: false,
      subViewMode: false,
      fields: [
        {
          key: "name",
          label: "Name",
        },

        {
          key: "created_at",
          label: "Date Created",
        },

        {
          key: "actions",
          label: "",
        },
      ],
      subFields: [
        {
          key: "name",
          label: "Name",
        },

        {
          key: "lga",
          label: "LGA",
        },

        {
          key: "price",
          label: "Price",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "";
          },
        },

        {
          key: "actions",
          label: "",
        },
      ],
    };
  },

  methods: {
    getData() {
      this.busy = true;
      this.$http
        .get("all-shipping-classes")
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload.shipping_classes.data;
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    getRecord(e, value) {
      this.item = e;
      if (value === "edit") {
        this.editMode = true;
        this.toggleModal();
      } else {
        this.$store.dispatch("shipping/setShippingZones", e);
        this.$router.push("/shipping-class/" + e.id);
      }
    },

    getSubRecord(e, value) {
      this.subItem = e;
      console.log(e, "View Sub Shipping zones", value);
      this.subViewMode = true;
      this.subToggleModal();
    },

    getSubItem(value) {
      this.drawer = true;
      this.details = value;
    },

    closeModal() {
      this.dialogVisible = !this.dialogVisible;
      this.item = {};
      this.editMode = false;
      this.viewMode = false;
    },

    closeSubModal() {
      this.subDialogVisible = !this.subDialogVisible;
      this.subItem = {};
      this.subEditMode = false;
      this.subViewMode = false;
    },

    complete(value) {
      this.$http
        .post(`/admin/remove-shipping-class/${value.id}`)
        .then((res) => {
          this.$swal.fire("Successful!", `${res.data.message}`, "success");
          this.getData();
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteRecord(value) {
      this.$swal
        .fire({
          title: "Continue?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.complete(value);
          }
        });
    },

    subComplete(value) {
      this.$http
        .post(`/admin/remove-shipping-zone/${value.id}`)
        .then((res) => {
          this.$swal.fire("Successful!", `${res.data.message}`, "success");
          this.getData();
          this.drawer = false;
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteSubRecord(value) {
      this.$swal
        .fire({
          title: "Continue?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.subComplete(value);
          }
        });
    },

    toggleModal() {
      if (this.editMode) {
        this.title = "Edit";
        this.dialogVisible = !this.dialogVisible;
      } else if (this.viewMode) {
        this.title = "View";
        this.dialogVisible = !this.dialogVisible;
      } else {
        this.title = "Create New";
        this.dialogVisible = !this.dialogVisible;
      }
    },

    subToggleModal() {
      // if (this.subEditMode) {
      //   this.subTitle = "Edit";
      //   this.subDialogVisible = !this.subDialogVisible;
      // } else
      console.log(this.subViewMode, "hhyheyhe");
      if (this.subViewMode) {
        this.subTitle = "View";
        this.subDialogVisible = !this.subDialogVisible;
      } else {
        this.subTitle = "Create New";
        this.subDialogVisible = !this.subDialogVisible;
      }
    },

    done() {
      this.closeModal();
      this.getData();
    },

    subDone() {
      this.getData();
      this.closeSubModal();
      this.drawer = false;
    },

    viewRecord(value) {
      this.$router.push(`store/${value.id}`);
    },
  },

  // watch: {
  //   items: {
  //     handler(newVal, oldVal) {
  //       if (oldVal !== newVal) {
  //         this.getSubItem(this.details);
  //       }
  //     },
  //     deep: true,
  //   },
  // },

  mounted() {
    this.getData();
  },
};
</script>

<style></style>
