<template>
  <div>
    <div class="bg-gray-100 flex gap-2 mb-6 rounded-sm p-2">
      <span
        role="button"
        v-for="(item, idx) in tabs"
        :key="idx"
        @click="activateTab(item, idx)"
        :class="[
          item.name === active
            ? 'bg-primaryColor text-white font-semibold'
            : 'bg-white'
        ]"
        class="font-medium text-[12px] py-[8px] px-4 block rounded-sm"
      >
        {{ item.title }}
      </span>
    </div>

    <div class="">
      <h5 class="font-bold">{{ activeName }}</h5>
      <div class="mt-3">
        <component :is="tabs[activeTab].component" :status="status" />
      </div>
    </div>
  </div>
</template>

<script>
import categories from "@/components/Newsletter/categories.vue";
import Mailing_list from "@/components/Newsletter/mailing_list.vue";
import Messages from "@/components/Newsletter/messages.vue";

export default {
  components: { categories, Mailing_list, Messages },
  data() {
    return {
      items: [],
      display: false,
      tabs: [
        {
          title: "All Mails",
          name: "all",
          component: Messages,
          status: ""
        },
        {
          title: "Published",
          name: "published",
          component: Messages,
          status: "published"
        },
        {
          title: "Drafts",
          name: "drafts",
          component: Messages,
          status: "draft"
        },
        {
          title: "Categories",
          name: "categories",
          component: categories,
        },
        {
          title: "Mailing List",
          name: "mailing_list",
          component: Mailing_list,
        },
      ],
      active: "all",
      activeName: "All Mails",
      activeTab: 0,
      status: ""
    };
  },
  methods: {
    activateTab(item, idx) {
      this.active = item.name;
      this.activeName = item.title;
      this.activeTab = idx;
      this.status = item.status
    },
  },

  beforeMount() {},
};
</script>

<style></style>
