<template>
  <div>
    <div class="flex mb-3 bg-white p-3">
      <span
        role="button"
        v-for="(item, idx) in tabs"
        :key="idx"
        @click="activateTab(item, idx)"
        :class="[
          item.title === active
            ? 'border-primaryColor border-2 text-primaryColor font-medium'
            : 'border',
        ]"
        class="font-medium first:border-r-none last:border-l-none capitalize text-[12px] py-[8px] px-4 block"
      >
        {{ item.title.split("-").join(" ") }}
      </span>
    </div>
    <div>
      <component :is="tabs[activeTab].component" />
    </div>
  </div>
</template>

<script>
import ModuleManagement from "./roles_and_permissions/ModuleManagement.vue";
import RoleManagement from "./roles_and_permissions/RoleManagement.vue";
export default {
  data() {
    return {
      active: "roles-management",
      tabs: [
        {
          title: "roles-management",
          component: RoleManagement,
        },
        {
          title: "module-management",
          component: ModuleManagement,
        },
      ],
      activeTab: 0,
    };
  },

  methods: {
    activateTab(item, idx) {
      this.active = item.title;
      console.log(idx);
      this.activeTab = idx;
    },
  },
};
</script>

<style></style>
