import Products from "@/pages/Inventory/products/indexPage.vue";
import ProductID from "@/pages/Inventory/products/_uuid.vue";

import Users from "@/pages/Inventory/users/indexPage.vue"
import UserDetails from "@/pages/Inventory/users/_uuid.vue";

import Stores from "@/pages/Inventory/stores/indexPage.vue"
import StoreID from "@/pages/Inventory/stores/_uuid.vue";

import Admins from "@/pages/Inventory/admins/IndexPage.vue"

const router = [
  {
    path: "/inventory/products",
    name: "products",
    component: Products,
    meta: {
      layout: "AppDashboardLayout",
      parent: "inventory",
      name: "products",
      header: "All Products",
      requiresAuth: true,
      description:
        "All products created by vendors. You can delete or view them",
    },
  },

  {
    path: "/inventory/products/:slug",
    name: "product-details",
    component: ProductID,
    meta: {
      layout: "AppDashboardLayout",
      parent: "inventory",
      name: "products",
      header: "Single Product",
      requiresAuth: true,
      description: "Product Details",
    },
  },

  {
    path: "/inventory/all-users",
    name: "all-users",
    component: Users,
    meta: {
      layout: "AppDashboardLayout",
      parent: "inventory",
      name: "all-users",
      header: "All Users",
      requiresAuth: true,
      description:
        "All users using warehauze.",
    },
  },

  {
    path: "/inventory/user/:id",
    name: "user-details",
    component: UserDetails,
    meta: {
      layout: "AppDashboardLayout",
      parent: "inventory",
      name: "all-users",
      header: "User Details",
      requiresAuth: true,
      description:
        "User Details.",
    },
  },

  {
    path: "/inventory/stores",
    name: "stores",
    component: Stores,
    meta: {
      layout: "AppDashboardLayout",
      parent: "inventory",
      name: "stores",
      header: "All Stores",
      requiresAuth: true,
      description:
        "All stores created by vendors.",
    },
  },

  {
    path: "/inventory/store/:id",
    name: "store-details",
    component: StoreID,
    meta: {
      layout: "AppDashboardLayout",
      parent: "inventory",
      name: "stores",
      requiresAuth: true,
      header: "Single Store",
      description: "Store Details",
    },
  },

  {
    path: "/inventory/admins",
    name: "admins",
    component: Admins,
    meta: {
      layout: "AppDashboardLayout",
      parent: "inventory",
      name: "admins",
      header: "All admins",
      requiresAuth: true,
      description:
        "All admins created by super admin.",
    },
  },

  {
    path: "/inventory/admin/:id",
    name: "admin-details",
    component: StoreID,
    meta: {
      layout: "AppDashboardLayout",
      parent: "inventory",
      name: "admins",
      requiresAuth: true,
      header: "Single Store",
      description: "Store Details",
    },
  },
];

export default router;
