import IndexViewVue from "@/pages/Dashboard/IndexPage.vue";
import Settings from "@/pages/Settings/IndexPage.vue"
import VendorKYCIDVue from "@/pages/Settings/VendorKYCID.vue";
import ManufacturerID from "@/pages/Settings/ManufacturerApplicationID.vue";

import ShippingZones from "@/pages/Settings/ShippingZones.vue"
import ShippingMethods from "@/pages/Settings/ShippingMethods.vue"


import Newsletters from "@/pages/Newsletter/IndexPage.vue";
import CreateNewsletter from "@/pages/Newsletter/create.vue"
import ViewNewsletter from "@/pages/Newsletter/_uuid.vue"
import EditNewsletter from "@/pages/Newsletter/edit.vue"
import MailingList from "@/pages/Newsletter/mailing_list.vue"

import Coupons from "@/pages/Coupons/IndexPage.vue"
import NotificationsPage from "@/pages/NotificationsPage.vue";

const router = [
  {
    path: "/",
    name: "analytics",
    redirect: '/analytics',
    component: IndexViewVue,
    meta: {
      layout: "AppDashboardLayout",
      parent: "analytics",
      name: "home",
      header: "Analytics",
      requiresAuth: true,
      description:
        "Analysis of information in warehauze",
    },
  },
  {
    path: "/analytics",
    name: "analytics",
    component: IndexViewVue,
    meta: {
      layout: "AppDashboardLayout",
      parent: "analytics",
      name: "home",
      header: "Analytics",
      requiresAuth: true,
      description:
        "Analysis of information in warehauze",
    },
  },

  {
    path: "/dashboard/settings",
    name: "dashboard-settings",
    component: Settings,
    meta: {
      layout: "AppDashboardLayout",
      parent: "settings",
      name: "home",
      header: "Settings",
      requiresAuth: true,
      description:
        "Warehauze configurations",
    },
  },

  {
    path: "/vendor-kyc/:id",
    name: "vendor-kyc-details",
    component: VendorKYCIDVue,
    meta: {
      layout: "AppDashboardLayout",
      parent: "settings",
      name: "home",
      header: "Vendor KYC Details",
      requiresAuth: true,
      description:
        "Details of submitted Vendor KYC",
    },
  },

  {
    path: "/manufacturer-application/:id",
    name: "manufacturer-application-details",
    component: ManufacturerID,
    meta: {
      layout: "AppDashboardLayout",
      parent: "settings",
      name: "home",
      header: "Manufacturer Application Details",
      requiresAuth: true,
      description:
        "Details of submitted application to become a manufacturer",
    },
  },

  {
    path: "/shipping-class/:id",
    name: "shipping-class-details",
    component: ShippingZones,
    meta: {
      layout: "AppDashboardLayout",
      parent: "settings",
      name: "home",
      header: "Shipping Zones",
      requiresAuth: true,
      description:
        "List of Shipping zones in a shipping class",
    },
  },

  {
    path: "/shipping-zones/:id",
    name: "shipping-zones-details",
    component: ShippingMethods,
    meta: {
      layout: "AppDashboardLayout",
      parent: "settings",
      name: "home",
      header: "Shipping Methods",
      requiresAuth: true,
      description:
        "List of Shipping methods in a shipping zones",
    },
  },

  {
    path: "/newsletter",
    name: "newsletter",
    component: Newsletters,
    meta: {
      layout: "AppDashboardLayout",
      parent: "newsletter",
      name: "newsletter",
      header: "Newsletter",
      requiresAuth: true,
      description:
        "Newsletters",
    },
  },

  {
    path: "/create-newsletter",
    name: "create-newsletter",
    component: CreateNewsletter,
    meta: {
      layout: "AppDashboardLayout",
      parent: "newsletter",
      name: "newsletter",
      header: "Create Newsletter",
      requiresAuth: true,
      description:
        "Create new Newsletters",
    },
  },

  {
    path: "/newsletter/:id/view",
    name: "view-newsletter",
    component: ViewNewsletter,
    meta: {
      layout: "AppDashboardLayout",
      parent: "newsletter",
      name: "newsletter",
      header: "View Newsletter",
      requiresAuth: true,
      description:
        "View Newsletter",
    },
  },

  {
    path: "/newsletter/:id/edit",
    name: "edit-newsletter",
    component: EditNewsletter,
    meta: {
      layout: "AppDashboardLayout",
      parent: "newsletter",
      name: "newsletter",
      header: "Edit Newsletter",
      requiresAuth: true,
      description:
        "Edit newsletter",
    },
  },

  {
    path: "/newsletter/categories/mailing-list/:id",
    name: "view-mailing-list",
    component: MailingList,
    meta: {
      layout: "AppDashboardLayout",
      parent: "newsletter",
      name: "newsletter",
      header: "Category Details - Mailing List",
      requiresAuth: true,
      description:
        "Category details and mailing list management",
    },
  },

  {
    path: "/coupons",
    name: "coupons",
    component: Coupons,
    meta: {
      layout: "AppDashboardLayout",
      parent: "coupons",
      name: "coupons",
      header: "Coupons",
      requiresAuth: true,
      description:
        "All Coupons generated in the system",
    },
  },

  {
    path: "/notifications",
    name: "notifications",
    component: NotificationsPage,
    meta: {
      layout: "AppDashboardLayout",
      parent: "notifications",
      name: "notifications",
      header: "Notifications",
      requiresAuth: true,
      description:
        "All notifications/activity log in the system",
    },
  },
 

  
];

export default router;
