<template>
  <div class="flex flex-col gap-4">
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <div class="grid grid-cols-5 gap-4">
          <b-skeleton
            v-for="item in 5"
            :key="item"
            width="100%"
            height="80px"
          ></b-skeleton>
        </div>
      </template>
      <div class="flex gap-4">
        <div
          class="w-full bg-gradient-to-r p-3 rounded-lg"
          :class="{
            'from-red-400 to-red-500': name === 'total_products',
            'from-green-400 to-green-500': name === 'total_payments',
            'from-amber-400 to-amber-500': name === 'total_orders',
            'from-cyan-400 to-cyan-500': name === 'total_users',
            'from-slate-400 to-slate-500': name === 'total_shops',
          }"
          v-for="(value, name) in cardsData"
          :key="name"
        >
          <span class="text-xs font-semibold uppercase">{{
            name.split("_").join(" ")
          }}</span>
          <div class="font-bold text-2xl">
            <slot :name="name" :value="value">{{ value ? value : "0" }} </slot>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>

    <div class="flex gap-4">
      <div class="bg-gray-100 p-4 rounded-lg w-full">
        <h6 class="font-semibold">Products</h6>
        <hr class="my-2">
        <div class="flex flex-col gap-3">
          <div v-for="(value, name) in productsData" :key="name">
            <div class="flex gap-1 items-center">
              <h6 class="text-xs font-medium">
                {{ name.split("_").join(" ") }}
              </h6>
              <h6>{{ value }}</h6>
            </div>
            <span
              class="block rounded-lg h-3"
              :class="{
                'bg-amber-100': name === 'pending_products',
                'bg-red-100': name === 'rejected_products',
                'bg-blue-100': name === 'approved_products',
              }"
              :style="{
                width:
                  `${getPercentage(
                    analytics.products_total,
                    analytics.products_total
                  )}` + '%',
              }"
            >
              <span
                class="block rounded-lg h-3"
                :class="{
                  'bg-amber-500': name === 'pending_products',
                  'bg-red-500': name === 'rejected_products',
                  'bg-blue-500': name === 'approved_products',
                }"
                :style="{
                  width:
                    `${getPercentage(value, analytics.products_total)}` + '%',
                }"
              >
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="bg-gray-100 p-4 rounded-lg w-full">
        <h6 class="font-semibold">Shops</h6>
        <hr class="my-2">
        <div class="flex flex-col gap-3">
          <div v-for="(value, name) in shopsData" :key="name">
            <div class="flex gap-1 items-center">
              <h6 class="text-xs font-medium">
                {{ name.split("_").join(" ") }}
              </h6>
              <h6>{{ value }}</h6>
            </div>
            <span
              class="block rounded-lg h-3"
              :class="{
                'bg-red-100': name === 'deactivated_shops',
                'bg-green-100': name === 'activated_shops',
              }"
              :style="{
                width:
                  `${getPercentage(
                    analytics.shops_total,
                    analytics.shops_total
                  )}` + '%',
              }"
            >
              <span
                class="block rounded-lg h-3"
                :class="{
                  'bg-red-500': name === 'deactivated_shops',
                  'bg-green-500': name === 'activated_shops',
                }"
                :style="{
                  width:
                    `${getPercentage(value, analytics.shops_total)}` + '%',
                }"
              >
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="bg-gray-100 p-4 rounded-lg w-full">
        <h6 class="font-semibold">Orders</h6>
        <hr class="my-2">
        <div class="flex flex-col gap-3">
          <div v-for="(value, name) in ordersData" :key="name">
            <div class="flex gap-1 items-center">
              <h6 class="text-xs font-medium">
                {{ name.split("_").join(" ") }}
              </h6>
              <h6>{{ value }}</h6>
            </div>
            <span
              class="block rounded-lg h-3"
              :class="{
                'bg-amber-100': name === 'pending_orders',
                'bg-green-100': name === 'delivered_orders',
                'bg-cyan-100': name === 'in_progress_orders',
                'bg-red-100': name === 'canceled_orders',
                'bg-blue-100': name === 'completed_orders',
              }"
              :style="{
                width:
                  `${getPercentage(
                    analytics.orders_total,
                    analytics.orders_total
                  )}` + '%',
              }"
            >
              <span
                class="block rounded-lg h-3"
                :class="{
                  'bg-amber-500': name === 'pending_orders',
                  'bg-green-500': name === 'delivered_orders',
                  'bg-cyan-500': name === 'in_progress_orders',
                  'bg-red-500': name === 'canceled_orders',
                  'bg-blue-500': name === 'completed_orders',
                }"
                :style="{
                  width:
                    `${getPercentage(value, analytics.orders_total)}` + '%',
                }"
              >
              </span>
            </span>
          </div>
        </div>
      </div>

      <!-- <div class="bg-white p-4 rounded-lg w-full">
        <h6 class="font-semibold">Payments</h6>
        <hr class="my-2">
        <div class="flex flex-col gap-3">
          <div v-for="(value, name) in paymentsData" :key="name">
            <div class="flex gap-1 items-center">
              <h6 class="text-xs font-medium">
                {{ name.split("_").join(" ") }}
              </h6>
              <h6>{{ value }}</h6>
            </div>
            <span
              class="block rounded-lg h-3"
              :class="{
                'bg-amber-100': name === 'pending_payments',
                'bg-green-100': name === 'delivered_payments',
                'bg-cyan-100': name === 'in_progress_payments',
                'bg-red-100': name === 'canceled_payments',
                'bg-blue-100': name === 'completed_payments',
              }"
              :style="{
                width:
                  `${getPercentage(
                    analytics.payments_total,
                    analytics.payments_total
                  )}` + '%',
              }"
            >
              <span
                class="block rounded-lg h-3"
                :class="{
                  'bg-amber-500': name === 'pending_payments',
                  'bg-green-500': name === 'delivered_payments',
                  'bg-cyan-500': name === 'in_progress_payments',
                  'bg-red-500': name === 'canceled_payments',
                  'bg-blue-500': name === 'completed_payments',
                }"
                :style="{
                  width:
                    `${getPercentage(value, analytics.payments_total)}` + '%',
                }"
              >
              </span>
            </span>
          </div>
        </div>
      </div> -->
    </div>

    <!-- <div class="bg-white p-4 rounded-lg">
      <h5 class="font-semibold">Recent Orders</h5>
      <table-component
        :items="items"
        :fields="fields"
        :busy="busy"
        @view="viewOrder"
        :disableEditAction="true"
        :disableDeleteAction="true"
        :showBaseCount="false"
      >
        <template #button>
          <span
            class="text-xs text-primary underline"
            role="button"
            @click="$router.push('/orders')"
          >
            View All Orders
          </span>
        </template>
      </table-component>
    </div> -->
  </div>
</template>

<script>
import { pick } from "lodash";
// import TableComponent from "@/components/TableComponent.vue";
export default {
  // components: { TableComponent },
  data() {
    return {
      analytics: {},
      displayKey: [
        "total_products",
        "total_payments",
        "total_orders",
        "total_users",
        "total_shops"
      ],
      items: [],
      fields: [
        {
          key: "id",
          label: "#",
          formatter: (val) => {
            let result = val.slice(0, 5);
            return `#${result}`;
          },
        },

        {
          key: "total_quantity",
          label: "Order Qty",
          formatter: (item) => {
            return item ? Number(item).toLocaleString() : "0";
          },
        },

        {
          key: "total_amount",
          label: "Total Amount",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "NGN 0.00";
          },
        },

        {
          key: "user.username",
          label: "Customer",
        },

        {
          key: "item-status",
          label: "Status",
        },

        {
          key: "created_at",
          label: "Date",
        },

        { key: "actions", label: "" },
      ],
      busy: false,
      loading: false,
    };
  },
  methods: {
    getAnalytics() {
      this.loading = true;
      this.$http.get(`admin/dashboard`)
        .then((res) => {
          let resPayload = res.data;
          this.analytics = resPayload;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    getPercentage(value1, value2) {
      let perc = (value1 / value2) * 100;
      return perc;
    },

    viewOrder(e) {
      this.$router.push(`/order/${e.id}`);
    },

    // list(page = 1) {
    //   this.busy = true;
    //   console.log(page, "kkk");
    //   this.$request
    //     .get(`admin/orders?page=` + page)
    //     .then((res) => {
    //       let resPayload = res.data;
    //       this.items = resPayload.data.splice(0, 5);
    //       this.totalRows = resPayload.total;
    //       this.showFrom = resPayload.from;
    //       this.showTo = resPayload.to;
    //       this.totalRecords = resPayload.total;
    //       this.currentPage = resPayload.current_page;
    //       this.perPage = resPayload.per_page;
    //       this.pages = Math.ceil(resPayload.total / resPayload.per_page);
    //       console.log(res.data);
    //       this.busy = false;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.busy = false;
    //     });
    // },
  },

  beforeMount() {
    this.getAnalytics();
    // this.list();
  },

  computed: {
    cardsData() {
      let data = {
        total_products: this.analytics.products_total,
        total_payments: this.analytics.payments_total,
        total_orders: this.analytics.orders_total,
        total_users: this.analytics.users_total,
        total_shops: this.analytics.shops_total
      };
      return pick(data, this.displayKey);
    },

    productsData(){
      let displayKey = [
        "pending_products",
        "approved_products",
        "rejected_products",
      ];
      let data = {
        pending_products: this.analytics.pending_products_total,
        approved_products: this.analytics.approved_products_total,
        rejected_products: this.analytics.rejected_products_total,
      };
      return pick(data, displayKey);
    },

    shopsData(){
      let displayKey = [
        "activated_shops",
        "deactivated_shops",
      ];
      let data = {
        activated_shops: this.analytics.activated_shops_total,
        deactivated_shops: this.analytics.deactivated_shops_total,
      };
      return pick(data, displayKey);
    },


    ordersData() {
      let displayKey = [
        "pending_orders",
        "in_progress_orders",
        "completed_orders",
        "delivered_orders",
        "canceled_orders",
      ];
      let data = {
        pending_orders: this.analytics.pending_orders_total,
        in_progress_orders: this.analytics.inprogress_orders_total,
        completed_orders: this.analytics.completed_orders_total,
        delivered_orders: this.analytics.delivered_orders_total,
        canceled_orders: this.analytics.canceled_orders_total,
      };
      return pick(data, displayKey);
    },

    paymentsData() {
      let displayKey = [
        "pending_payments",
        "in_progress_payments",
        "completed_payments",
        "delivered_payments",
        "canceled_payments",
      ];
      let data = {
        pending_payments: this.analytics.pending_payments_total,
        in_progress_payments: this.analytics.inprogress_payments_total,
        completed_payments: this.analytics.completed_payments_total,
        delivered_payments: this.analytics.delivered_payments_total,
        canceled_payments: this.analytics.canceled_payments_total,
      };
      return pick(data, displayKey);
    },
  },
};
</script>

<style></style>
