<template>
    <div class="auth-content">
      <div>
        <div class="mb-4">
          <img src="@/assets/img/logo.svg" class="h-20 w-20" alt="" />
          <h5 class="font-bold text-xxl mb-0">Welcome back,</h5>
          <small class="text-xs block text-gray-800"
            >Sign in to continue to Warehauze administrative panel</small
          >
        </div>
        <validation-observer v-slot="{ invalid, handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <span v-if="error" class="mb-5 block text-xs text-red-600">{{ error }}</span>
            <div>
              <validation-provider
                name="email"
                rules="required|email"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="email">Email</label>
                <div
                  class="input-field"
                  :class="{
                    error: dirty && invalid,
                  }"
                >
                  <input
                    type="text"
                    name="text"
                    id="email"
                    v-model="credentials.email"
                    placeholder="email"
                  />
                  <span class="email-iccon">
                    <i-icon icon="heroicons:user" class="form-icon" />
                  </span>
                </div>
                <span class="text-xs text-red-600" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
  
            <div class="my-3">
              <validation-provider
                class=""
                name="password"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="password">Password</label>
                <div
                  class="input-field"
                  :class="{
                    error: dirty && invalid,
                  }"
                >
                  <input
                    :type="typePassword ? 'password' : 'text'"
                    name="password"
                    id="password"
                    v-model="credentials.password"
                    placeholder="Password"
                  />
                  <span
                    class="password-iccon"
                    role="button"
                    @click="typePassword = !typePassword"
                  >
                    <i-icon
                      :icon="
                        typePassword ? 'tabler:eye' : 'gridicons:not-visible'
                      "
                      class="form-icon"
                    />
                  </span>
                </div>
                <span class="text-xs text-red-600" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
  
            <div class="mt-4">
              <button
                class="warehauze-btn warehauze-primary w-100"
                v-bind:disabled="invalid"
                :class="{ 'bg-gray-400': invalid }"
              >
                <span>Sign in</span>
              </button>
            </div>
          </form>
        </validation-observer>
        <!-- <div class="text-center mt-3">
          <span class="text-xs font-semibold"
            >Don't have an account?
            <router-link to="/sign-up" class="text-primary"
              >Sign Up</router-link
            >
          </span>
        </div> -->
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapState } from "vuex";
  export default {
    components: {},
    data: () => {
      return {
        credentials: {
          email: "",
          password: "",
        },
        typePassword: true,
      };
    },
    methods: {
      ...mapActions("auth", ["userLogin"]),
  
      onSubmit() {
        let credentials = {
          email: this.credentials.email,
          password: this.credentials.password,
        };
        console.log(credentials);
        // this.$router.push('/analytics')
        this.userLogin(credentials)
      },
    },
  
    mounted() {
      // this.$store.commit("auth/REMOVE_ERROR_SUCCESS");
    },
  
    watch: {},
  
    computed: {
      ...mapState("auth", {
        loading: (state) => state.loading,
        error: (state) => state.error,
        errors: (state) => state.validationErrors,
        user: (state) => state.user,
        success: (state) => state.success,
      }),
    },
  };
  </script>
  