<template>
  <div>
    <div class="users" style="width: 100%">
      <div v-if="busy">Retrieving Data</div>
      <div v-else id="" class="bg-white p-6">
        <!-- <h4 class="font-weight-bold mb-3">Product Details</h4> -->
        <div class="">
          <div class="d-flex align-items-center mb-3" style="gap: 10px">
            <h4 class="font-bold capitalize">{{ product.name }}</h4>
            <span :class="product.status"> {{ product.status }} </span>
          </div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="Overview">
              <OverView :product="product" @refresh="getProduct" />
            </el-tab-pane>
            <el-tab-pane label="Description">
              <ProductDescription :product="product" />
            </el-tab-pane>
            <el-tab-pane label="Media">
              <ProductMedia :product="product" />
            </el-tab-pane>
            <el-tab-pane label="Reviews and Ratings">
              <ReviewsPage :item="product" />
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="mt-4" v-if="product.status === 'pending'">
          <div class="d-flex justify-content-end" style="gap: 20px">
            <button
              class="p-2 text-white font-semibold text-[12px] rounded-md bg-green-600 w-auto"
              @click="updateProductStatus('approved')"
              :disabled="shippingClass === null"
              :class="{ 'bg-gray-600': shippingClass === null }"
            >
              Approve Product
            </button>
            <button
              class="p-2 text-white font-semibold text-[12px] rounded-md bg-red-600 w-auto"
              @click="updateProductStatus('rejected')"
            >
              Reject Product
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OverView from "@/components/Inventory/products/overView.vue";
import ProductDescription from "@/components/Inventory/products/productDescription.vue";
import ProductMedia from "@/components/Inventory/products/productMedia.vue";
import ReviewsPage from "@/components/Inventory/products/reviewsPage.vue";
export default {
  data() {
    return {
      activeName: "",
      busy: false,
      product: {},
      slug: this.$route.params.slug,
    };
  },
  methods: {
    updateProductStatus(value) {
      var payload = {
        id: this.product.id,
        status: value,
        slug: this.product.slug,
      };
      console.log(payload);
      this.$http
        .post(`admin/edit-product-status/${payload.id}`, payload)
        .then((res) => {
          console.log(res);
          this.busy = false;
          this.getProduct(payload.slug);
          this.$toastify({
            text: `Product Status updated`,
            className: "info",
            style: {
              background: "green",
              fontSize: "12px",
              borderRadius: "3px",
            },
          }).showToast();
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    getProduct() {
      this.busy = true;
      this.$http
        .get(`admin/show-product/${this.slug}`)
        .then((res) => {
          let resPayload = res.data;
          this.product = resPayload.product;
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },
  },

  beforeMount() {
    this.getProduct();
  },
  computed: {
    shippingClass() {
      return this.product.shipping_class;
    },
    // loading() {
    //   return this.$store.getters["products/loading"];
    // },
    // product() {
    //   return this.$store.getters["products/singleProduct"];
    // },
  },
  components: { OverView, ProductDescription, ProductMedia, ReviewsPage },
};
</script>
