<template>
  <div
    class=" bg-[#fff] pt-4 scroll-smooth border-0 rounded-md mb-5"
    style=""
  >
    <div>
      <div class="px-6 flex items-center justify-between">
        <div class="">
          <div class="flex items-start space-x-1" >
            <h4 class="text-xl text-capitalize font-bold text-dark-400">{{ title }}</h4>
            <span :class="status">{{ status }}</span>
          </div>
          <span class="text-primary font-[400] text-[14px]" v-if="email">{{
            email
          }}</span>
        </div>
        <div class="flex justify-between">
          <slot name="card-icons"></slot>
        </div>
      </div>
      <hr class="ml-[220px] mt-2 mb-0" />
      <div class="card-body flex px-6 align-items-center">
        <slot name="left-body">
          <div class="col-lg-2 relative flex items-center">
            <div
              class="bg-gray-100 flex items-center justify-center h-[100px] w-[100px] object-center rounded-full"
            >
              <h1 class="text-primary mb-0 font-bold uppercase">
                {{ getInitals }}
              </h1>
            </div>
            <!-- <img
                  class="h-[100px] w-[100px] object-cover object-center rounded-full bg-gray6"
                  :src="picture"
                />
                <div
                  class="bg-primary cursor-pointer h-4 w-5 flex justify-center items-center rounded-[5px] absolute bottom-2 left-14"
                  @click.prevent="$refs.imageInput.click()"
                >
                  <Icon
                    icon="material-symbols:change-circle-rounded"
                    class="text-sm text-white"
                  />
                </div>
                <input
                  ref="imageInput"
                  type="file"
                  class="d-none"
                  :accept="format.join(',')"
                  @change.prevent="uploadImage()"
                /> -->
          </div>
        </slot>
        <slot name="main-body">
          <div class="pl-6 mt-3 w-full">
            <div class="grid grid-cols-3 gap-3">
              <div
                v-for="(value, name) in filterData"
                :key="value"
                class=""
              >
                <span
                  class="text-xs text-light capitalize font-medium"
                  >{{ name.split("_").join(" ") }}:</span
                >
                <div
                  v-if="typeof value !== 'object'"
                  class="font-medium text-sm"
                >
                  <slot :name="name" :value="value">
                    {{ value ? value : "No data available" }}
                    </slot
                  >
                </div>
              </div>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { pick } from "lodash";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: false,
    },
    displayKey: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      format: [".jpg", ".jpeg", ".png"],
      profile_picture: this.data.profile_picture,
      isSticky: false,
      scrollPosition: null,
      months: "",
    };
  },
  computed: {
    getInitals() {
      return this.title.charAt(0);
    },

    filterData() {
      return pick(this.data, this.displayKey);
    },
   
    // picture() {
    //   if (this.profile_picture) {
    //     return this.profile_picture;
    //   } else if (!this.profile_picture && this.data.gender == "Female") {
    //     return require("@/assets/img/female-avatar.svg");
    //   } else if (!this.profile_picture && this.data.gender == "Male") {
    //     return require("@/assets/img/male-avatar.svg");
    //   }
    // },
  },
  methods: {
    
  },
};
</script>

<style scoped>
.transition {
  transition: opacity 2s ease;
  animation: slide-fade 0.3s ease-in-out 0s;
  /* opacity: 0; */
}
</style>
