<template>
  <div>
    <!-- <div class="flex justify-end">
      <filter-tabs :tabs="tabs" @filter-changed="filter($event)" />
    </div> -->
    <div>
      <table-component
        :items="items"
        :fields="fields"
        :disableDeleteAction="true"
        :disableEditAction="true"
        :busy="busy"
        @view="view"
        @next="filter($event)"
        :meta="meta"
      />
    </div>

    <!-- View Receipt -->
    <view-receipts
      :visible="dialogVisible"
      @toggle="close"
      :date="item.created_at"
      title="Withdrawal Details"
      :displayData="displayData"
      :data="item"
      :hasAction="true"
      :display-key="[
        'vendor_name',
        'charge',
        'amount',
        'status',
        'account_name',
        'account_number',
        'bank_name',
      ]"
      @payoutsFunc="payoutsFunc($event)"
      @copy="copyAccountNumber"
    />
  </div>
</template>

<script>
// import FilterTabs from "@/components/Utils/FilterTabs.vue";
import TableComponent from "@/components/TableComponent.vue";
import ViewReceipts from "@/components/Finance/ViewReceipts.vue";
export default {
  components: {
    // FilterTabs,
    TableComponent,
    ViewReceipts,
  },
  data() {
    return {
      tabs: [
        {
          title: "All Payments",
          label: "",
        },
        {
          title: "Fiat Payments",
          label: "flutterwave",
        },
        {
          title: "Crypto Payments",
          label: "cryptocurrency",
        },
      ],
      items: [],
      fields: [
        {
          key: "beneficiary",
          label: "Beneficiary",
        },
        {
          key: "amount",
          label: "Amount",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "";
          },
        },
        // {
        //   key: "channel",
        //   label: "Channel",
        // },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "created_at",
          label: "Date Processed",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      busy: false,
      dialogVisible: false,
      displayData: {
        vendor_name: "",
        charge: "",
        amount: "",
        status: "",
        account_name: "",
        account_number: "",
        bank_name: "",
      },
      item: {},
      meta: {},
      ID: "",
    };
  },

  methods: {
    filter(page = 1) {
      this.busy = true;
      this.$http
        .get(`admin/withdrawals?page=${page}`)
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload.data;
          this.meta = resPayload;
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    copyAccountNumber() {
      navigator.clipboard.writeText(this.displayData.account_number).then(
        (success) => {
          (alert('copied!')), console.log(success);
        },
        (err) => console.log("error", err)
      );
    },

    view(e) {
      console.log(e, "kkkk");
      this.ID = e;
      this.$http
        .get(`admin/withdrawals/${e.id}`)
        .then((res) => {
          let resPayload = res.data;
          console.log(resPayload, "ommmo");
          this.item = resPayload.payout;
          this.dialogVisible = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    payoutsFunc(e) {
      let payload = {
        status: e,
      };
      console.log(e, this.ID);
      this.$http
        .post(`admin/withdrawals/${this.ID.id}`, payload)
        .then((res) => {
          let resPayload = res.data;
          console.log(resPayload, "ommmo");
          this.view(this.ID);
          this.filter();
          this.$swal.fire("Done", resPayload.message, "success");
        })
        .catch((err) => {
          console.log(err);
          this.$swal.fire("Error", 'Not completed', "warnning");
        });
    },

    close() {
      this.dialogVisible = !this.dialogVisible;
    },
  },

  beforeMount() {
    let value = "";
    this.filter(value);
  },

  watch: {
    item: {
      handler(val) {
        if (val.user) {
          this.displayData = {
            vendor_name: val.beneficiary,
            amount: `${Number(val.amount).toLocaleString("en-US", {
              style: "currency",
              currency: "NGN",
            })}`,
            charge: `${Number(val.charge).toLocaleString("en-US", {
              style: "currency",
              currency: "NGN",
            })}`,
            status: val.status,
            account_name: val.user.bank_details.account_name,
            account_number: val.user.bank_details.account_no,
            bank_name: val.user.bank_details.bank_name,
          };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
