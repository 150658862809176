<template>
  <div>
    <div class="bg-gray-100 flex gap-2 mb-3 rounded-sm p-2">
        <span
          role="button"
          v-for="(item, idx) in tabs"
          :key="idx"
          @click="activateTab(item)"
          :class="[
            item.key === active
              ? 'bg-primaryColor text-white font-medium'
              : 'bg-white',
          ]"
          class="font-medium capitalize text-[12px] py-[8px] px-4 block rounded-sm"
        >
          {{ item.label.split("-").join(" ") }}
        </span>
      </div>
    <table-component
      :items="products"
      :busy="busy"
      :disableEditAction="true"
      :fields="fields"
      @view="view"
      @delete="deleteProduct"
      :meta="meta"
      @next="list($event)"
    />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";

export default {
  components: { TableComponent },
  data() {
    return {
      products: [],
      busy: false,
      fields: [
        {
          key: "app_image",
          label: "",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "price",
          label: "Price",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "";
          },
        },
        {
          key: "availability",
          label: "Availability",
        },
        {
          key: "created_at",
          label: "Date Created",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      tabs: [
        {
          label: 'All',
          key: ''
        },
        {
          label: 'retail',
          key: 'retailer'
        },
        {
          label: 'wholesale',
          key: 'wholesaler'
        }
      ],
      id: 0,
      currentPage: 1,
      perPage: 0,
      totalRows: 0,
      count: 1,
      pages: 1,
      meta: {},
      active: ''
    };
  },

  methods: {
    list(page = 1) {
      this.busy = true;
      this.$http
        .get(`admin/get-products?page=${page}&user_type=${this.active}`)
        .then((res) => {
          let resPayload = res.data;
          this.products = resPayload.data;
          var meta = resPayload;
          this.meta = meta
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    activateTab(item) {
      this.active = item.key
    },

    deleteProduct(value) {
      this.$http
        .post(`admin/delete-product/${value.id}`)
        .then((res) => {
          this.$swal.fire(
            "Deleted!",
            "Product deleted succesfully.",
            "success"
          );
          this.list();
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteRecord(value) {
      this.$swal
        .fire({
          title: "Continue?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          console.log(result, "kkk");
          if (result.isConfirmed) {
            this.deleteProduct(value);
          }
        });
    },

    view(value) {
      this.$router.push(`products/${value.slug}`);
    },
  },

  mounted() {
    this.list();
  },

  watch:{
    active: {
      handler(oldVal, newVal) {
        if(oldVal !== newVal) {
          this.list()
        }
      }
    }
  }
};
</script>

<style></style>
