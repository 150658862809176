var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('table-component',{attrs:{"items":_vm.items,"fields":_vm.fields,"disableDeleteAction":true,"disableEditAction":true,"busy":_vm.busy,"meta":_vm.meta},on:{"view":_vm.view,"next":function($event){return _vm.filter($event)}}})],1),_c('view-receipts',{attrs:{"visible":_vm.dialogVisible,"date":_vm.item.created_at,"title":"Payout Details","displayData":_vm.displayData,"data":_vm.item,"hasAction":true,"display-key":[
      'vendor_name',
      'charge',
      'amount',
      'status',
      'account_name',
      'account_number',
      'bank_name',
    ]},on:{"toggle":_vm.close,"payoutsFunc":function($event){return _vm.payoutsFunc($event)},"copy":_vm.copyAccountNumber}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }