<template>
  <div>
    <button
      @click="openModal"
      class="warehauze-btn warehauze-primary text-white flex items-center gap-2 ml-auto"
    >
      <span>
        <i-icon icon="jam:write" />
      </span>
      <span>Create Category</span>
    </button>
    <table-component
      :paginate="false"
      :items="items"
      :busy="isLoading"
      :fields="fields"
      @delete="deleteRecord"
      @edit="getRecord"
    @view="view"
    />

    <!-- Modal To Create Categories -->
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="40%"
      title="Mailing Category"
    >
      <div class="mt-6">
        <validation-observer v-slot="{ invalid, handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div>
              <validation-provider
                name="category name"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="name">Category Name</label>
                <div
                  class="input-field"
                  :class="{
                    error: dirty && invalid,
                  }"
                >
                  <input
                    type="text"
                    name="text"
                    id="name"
                    v-model="name"
                    placeholder="Mailing category name"
                  />
                </div>
                <span
                  class="text-xs block text-red-600"
                  v-if="errors"
                  >{{ errors[0] }}</span
                >
                <span
                  class="mb-5 block text-xs text-red-600"
                  v-for="error in validationErrors.name"
                  :key="error"
                  >{{ error }}</span
                >
              </validation-provider>
            </div>

            <div class="mt-4">
              <button
                class="warehauze-btn warehauze-primary w-100"
                v-bind:disabled="invalid"
                :class="{ 'bg-gray400': invalid, 'bg-gray400': busy }"
              >
                <span>{{ editMode ? "Update" : "Create" }}</span>
              </button>
            </div>
          </form>
        </validation-observer>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableComponent from "../TableComponent.vue";
export default {
  components: { TableComponent },
  data() {
    return {
      items: [],
      dialogVisible: false,
      editMode: false,
      name: null,
      dataObj: {},
      busy: false,
      validationErrors: {},
      isLoading: false,
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "created_at",
          label: "Created At",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      item: null
    };
  },
  methods: {
    async categoriesList() {
      this.isLoading = true;
      try {
        let res = await this.$http.get(`admin/mailing-categories`);
        console.log(res);
        this.items = res.data.mailingCategories;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    getRecord(e) {
      this.name = e.name
      this.editMode = true
      this.item = e
      this.openModal()
    },

    async deleteRecord(e){
      try {
        let res = await this.$http.post(
          `admin/mailing-categories/delete/${e.id}`
        );
        console.log(res);
        this.$toastify({
          text: res.data.message,
          className: "info",
          style: {
            background: "#333",
            fontSize: "11px",
            borderRadius: "3px",
          },
        }).showToast();
        this.categoriesList()
      } catch (error) {
        console.log(error);
      } finally {
        this.deleteLoading = null;
      }
    },

    onSubmit() {
      this.busy = true;
      var url = this.editMode
        ? `/admin/mailing-categories/update/${this.item.id}`
        : "admin/mailing-categories/add";
      const formData = new FormData();
      formData.append("name", this.name);
      this.$http
        .post(`${url}`, formData)
        .then((res) => {
          console.log(res.data);
          this.busy = false;
          this.$toastify({
            text: `${res.data.message}`,
            className: "info",
            position: "center",
            style: {
              background: "#1e1e1e",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.name = "";
          this.$emit("done");
        })
        .catch((err) => {
          this.busy = false;
          this.validationErrors = err.data.errors;
          console.log(err.data.errors);
          this.name = "";
          this.$toastify({
            text: `Error`,
            className: "info",
            position: "center",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          console.log(err);
        })
        .finally(() => {
          this.handleClose();
          this.categoriesList();
        });
    },

    openModal() {
      this.dialogVisible = !this.dialogVisible;
    },

    handleClose() {
      this.dialogVisible = !this.dialogVisible;
      this.name = null
      this.editMode = false
    },

    view(e) {
      return this.$router.push(`/newsletter/categories/mailing-list/${e.id}`)
    }
  },

  beforeMount() {
    this.categoriesList();
  },
};
</script>

<style></style>
