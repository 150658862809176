var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex justify-end mb-4"},[_c('filter-tabs',{attrs:{"tabs":_vm.tabs},on:{"filter-changed":function($event){return _vm.setChannel($event)}}})],1),_c('div',[_c('table-component',{attrs:{"items":_vm.items,"fields":_vm.fields,"disableDeleteAction":true,"disableEditAction":true,"busy":_vm.busy,"meta":_vm.meta},on:{"view":_vm.view,"next":function($event){return _vm.filter($event)}}})],1),_c('view-receipts',{attrs:{"visible":_vm.dialogVisible,"date":_vm.item.created_at,"title":"Payment Details","displayData":_vm.displayData,"data":_vm.item,"display-key":[
      'customer_name',
      'channel',
      'amount',
      'reference_number',
      'transaction_reference',
      'status',
      'description',
    ]},on:{"toggle":_vm.close}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }