<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="30%"
    >
      <div class="mt-6">
        <validation-observer v-slot="{ invalid, handleSubmit }">
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            class="flex flex-col gap-3"
          >
            <div>
              <validation-provider
                name="Code"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="name">Code</label>
                <span class="flex flex-col mb-1" v-if="!editMode">
                  <span class="text-red-500 block" v-if="!manual"
                    >Will be generated Automatically</span
                  >
                  <span
                    class="underline text-blue-500 text-sm"
                    role="button"
                    @click="manual = !manual"
                    >{{
                      manual
                        ? "Allow code to auto generate"
                        : "Enter Code Manually"
                    }}</span
                  >
                </span>
                <div
                  v-if="manual || editMode"
                  :class="{
                    error: dirty && invalid,
                  }"
                >
                  <input
                    type="text"
                    name="text"
                    id="name"
                    v-model="dataObj.code"
                    placeholder="Enter Code"
                    :readonly="editMode"
                    :disabled="editMode"
                  />
                </div>
                <span class="text-xs block text-red-600" v-if="errors">{{
                  errors[0]
                }}</span>
                <span
                  class="mb-5 block text-xs text-red-600"
                  v-for="error in validationErrors.category_name"
                  :key="error"
                  >{{ error }}</span
                >
              </validation-provider>
            </div>

            <div>
              <validation-provider
                name="Discount Percentage"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="name">Discount Percentage</label>
                <div
                  :class="{
                    error: dirty && invalid,
                  }"
                >
                  <input
                    type="text"
                    name="text"
                    id="name"
                    v-model="dataObj.discount_percentage"
                    placeholder="Enter Discount Percentage"
                  />
                </div>
                <span class="text-xs block text-red-600" v-if="errors">{{
                  errors[0]
                }}</span>
                <span
                  class="mb-5 block text-xs text-red-600"
                  v-for="error in validationErrors.category_name"
                  :key="error"
                  >{{ error }}</span
                >
              </validation-provider>
            </div>

            <div>
              <validation-provider
                name="Shop"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="name">Shop</label>
                <div
                  class=""
                  :class="{
                    error: dirty && invalid,
                  }"
                >
                  <!-- <input
                    type="text"
                    name="text"
                    id="name"
                    v-model="dataObj.code"
                    placeholder="Code"
                    :readonly="viewMode"
                    :disabled="viewMode"
                  /> -->
                  <select
                    name=""
                    :disabled="editMode"
                    class="input"
                    v-model="dataObj.shop_id"
                  >
                    <option value="" disabled selected>--Select Shop--</option>
                    <option
                      v-for="(item, idx) in shops"
                      :key="idx"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <span class="text-xs block text-red-600" v-if="errors">{{
                  errors[0]
                }}</span>
                <span
                  class="mb-5 block text-xs text-red-600"
                  v-for="error in validationErrors.category_name"
                  :key="error"
                  >{{ error }}</span
                >
              </validation-provider>
            </div>

            <div class="flex gap-3 items-center">
              <div class="w-full">
                <validation-provider
                  name="Start Date"
                  rules="required"
                  v-slot="{ dirty, invalid, errors }"
                >
                  <label for="name">Start Date</label>
                  <div
                    :class="{
                      error: dirty && invalid,
                    }"
                  >
                    <input
                      type="date"
                      name="text"
                      id="name"
                      v-model="dataObj.start_date"
                      placeholder="Enter Start Date"
                    />
                  </div>
                  <span class="text-xs block text-red-600" v-if="errors">{{
                    errors[0]
                  }}</span>
                  <span
                    class="mb-5 block text-xs text-red-600"
                    v-for="error in validationErrors.category_name"
                    :key="error"
                    >{{ error }}</span
                  >
                </validation-provider>
              </div>

              <div class="w-full">
                <validation-provider
                  name="End Date"
                  v-slot="{ dirty, invalid, errors }"
                >
                  <label for="name">End Date</label>
                  <div
                    :class="{
                      error: dirty && invalid,
                    }"
                  >
                    <input
                      type="date"
                      name="text"
                      id="name"
                      v-model="dataObj.end_date"
                      placeholder="Enter End Date"
                    />
                  </div>
                  <span class="text-xs block text-red-600" v-if="errors">{{
                    errors[0]
                  }}</span>
                  <span
                    class="mb-5 block text-xs text-red-600"
                    v-for="error in validationErrors.category_name"
                    :key="error"
                    >{{ error }}</span
                  >
                </validation-provider>
              </div>
            </div>

            <div class="mt-4">
              <button
                class="warehauze-btn warehauze-primary w-100"
                v-bind:disabled="invalid"
                :class="{ 'bg-gray400': invalid, 'bg-gray400': busy }"
              >
                <span>{{ editMode ? "Update" : "Generate" }}</span>
              </button>
            </div>
          </form>
        </validation-observer>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },

    data: {
      type: Object,
      default: () => {},
    },

    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataObj: {
        code: null,
        start_date: null,
        end_date: null,
        discount_percentage: null,
        shop_id: "",
      },
      busy: false,
      validationErrors: {},
      dialogVisible: false,
      manual: false,
      shops: [],
    };
  },

  methods: {
    list() {
      this.busy = true;
      this.$http
        .get(`admin/list-shops`)
        .then((res) => {
          let resPayload = res.data;
          this.shops = resPayload;
        })
        .catch((err) => {
          this.busy = false;
          return err;
        });
    },

    onSubmit() {
      this.busy = true;
      var url = this.editMode
        ? `/promo-codes/${this.data.id}/update`
        : "/promo-codes/add";
      this.$http
        .post(`${url}`, this.dataObj)
        .then((res) => {
          console.log(res.data);
          this.busy = false;
          this.$toastify({
            text: this.editMode ? `${res.data.message}` : `${res.data.message}`,
            className: "info",
            position: "center",
            style: {
              background: "#1e1e1e",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.dataObj.name = "";
          this.$emit("done");
        })
        .catch((err) => {
          this.busy = false;
          this.validationErrors = err.data.errors;
          console.log(err.data.errors);
          this.dataObj.name = "";
          this.$toastify({
            text: `Error`,
            className: "info",
            position: "center",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          console.log(err);
        });
    },

    handleClose() {
      this.validationErrors = {};
      this.$emit("toggle");
    },
  },

  beforeMount() {
    this.list();
  },

  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
      immediate: true,
    },

    data: {
      handler(val) {
        if (val) {
          this.dataObj = {
            code: val.code,
            start_date: val.start_date,
            end_date: val.end_date,
            discount_percentage: val.discount_percentage,
            shop_id: val.shop_id,
          };
        }
      },
      immediate: false,
    },
  },
};
</script>

<style></style>
