<template>
  <div>
    <!-- List of Newsletters  -->
    <div>
      <div class="flex items-center justify-between mb-3">
        <button
          @click="openDisplay"
          class="warehauze-btn warehauze-primary text-white flex items-center gap-2"
        >
          <span>
            <i-icon icon="jam:write" />
          </span>
          <span>Compose</span>
        </button>
      </div>
      <div v-if="loading">
        <b-skeleton-table
          :rows="4"
          :columns="3"
          :table-props="{ bordered: false, striped: false }"
        ></b-skeleton-table>
      </div>
      <div class="flex flex-col mt-3" v-else>
        <div
          v-for="(item, idx) in items"
          :key="idx"
          class="grid items-center grid-cols-6 gap-3 border-b first:border-t py-3 px-3 border-gray-200 hover:shadow-lg"
          role="button"
        >
          <span class="text-sm font-bold w-auto">{{
            item.name || "warehauze mail"
          }}</span>

          <div
            class="text-sm flex items-center gap-2 w-full col-span-3"
          >
            <span
              class="flex items-center gap-1 text-sm"
              v-html="item.subject"
            ></span>
            <span>
              <i-icon
                class=""
                v-if="item.attachment"
                icon="iconamoon:attachment"
              />
            </span>
          </div>

          <div
            class="flex items-center w-full justify-end gap-3 col-span-2"
          >
            <div class="flex items-center gap-3">
              <span role="button" @click="view(item)">
                <i-icon icon="grommet-icons:view" />
              </span>
              <span
                role="button"
                @click="edit(item)"
                v-if="item.status !== 'published'"
              >
                <i-icon icon="mage:edit" />
              </span>

              <el-popconfirm
                confirm-button-text="Delete"
                cancel-button-text="No, Thanks"
                icon="el-icon-info"
                icon-color="red"
                title="Are you sure to delete this newsletter?"
                @confirm="deleteRecord(item)"
              >
                <span slot="reference" class="text-red-600">
                  <i-icon
                    :icon="
                      deleteLoading === item.id
                        ? 'line-md:loading-alt-loop'
                        : 'fluent:delete-16-filled'
                    "
                  />
                </span>
              </el-popconfirm>
            </div>
            <span class="text-[12px] text-gray-400">
              {{ item.created_at | formatDate }}
            </span>
          </div>
        </div>
        <vPagination class="mt-4" :meta="meta" @next="next" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["status"],
  data() {
    return {
      items: [],
      dropdownItem: [
        { label: "view", icon: "grommet-icons:view" },
        { label: "edit", icon: "mage:edit" },
        { icon: "fluent:delete-16-filled", label: "delete" },
      ],
      messageStatus: "",
      loading: false,
      deleteLoading: null,
      filteredDropdownItem: [],
      meta: {},
    };
  },
  methods: {
    async list(page = 1) {
      this.loading = true;
      try {
        let res = await this.$http.get(
          `admin/newsletters?status=${this.messageStatus}&page=${page}`
        );
        console.log(res);
        this.items = res.data.data;
        const paginationData = res.data;
        this.meta = paginationData
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    next(page) {
      this.list(page);
    },

    openDisplay() {
      this.$router.push("/create-newsletter");
    },

    view(e) {
      this.$router.push(`newsletter/${e.id}/view`);
    },

    edit(e) {
      this.$router.push(`newsletter/${e.id}/edit`);
    },

    async deleteRecord(e) {
      this.deleteLoading = e.id;
      try {
        let res = await this.$http.post(`admin/newsletter/delete/${e.id}`);
        console.log(res);
        this.$toastify({
          text: res.data.message,
          className: "info",
          style: {
            background: "#333",
            fontSize: "11px",
            borderRadius: "3px",
          },
        }).showToast();
        this.list();
      } catch (error) {
        console.log(error);
      } finally {
        this.deleteLoading = null;
      }
    },

    filterDropdown(val) {
      if (val.status === "published") {
        console.log(this.dropdownItem.filter((item) => item.label !== "edit"));
        return this.dropdownItem.filter((item) => item.label !== "edit");
      } else {
        return this.dropdownItem;
      }
    },
  },

  beforeMount() {
    this.list();
  },

  watch: {
    status: {
      handler(val) {
        this.messageStatus = val;
        this.list();
      },
      immediate: true,
    },
  },
};
</script>
