<template>
  <div class="">
    <div class="flex justify-between items-center">
      <h5 v-if="busy">Retrieving Data ...</h5>
      <h5 class="font-bold">{{ details.name }}</h5>
      <button @click="subToggleModal" class="warehauze-btn warehauze-primary">
        Add Shipping Zone
      </button>
    </div>
    <hr class="my-4" />

    <!-- <h6 class="font-semibold mb-0">Shipping Zones</h6> -->
    <div v-if="busy">
      <span class="text-sm">Retrieving Shipping Zones ...</span>
    </div>
    <div v-else>
      <div v-if="Object.keys(zones).length > 0">
        <div v-for="(name, item) in zones" :key="item" class="mt-6">
          <h6 class="mb-0 font-semibold">
            {{ item === "" ? "No name found" : item }}
          </h6>
          <table-component
            :items="name"
            :disableDeleteAction="false"
            :fields="fields"
            :busy="busy"
            @view="getSubRecord"
            @edit="editSubRecord"
            @delete="deleteSubRecord"
            :paginate="false"
          >
          </table-component>
        </div>
      </div>

      <div v-else>
        <span class="text-red-500 text-sm">No Shipping Zone Found</span>
      </div>
    </div>

    <!-- Modal to Add or Edit SubCategories -->
    <div>
      <ShippingZone
        :visible="subDialogVisible"
        @toggle="closeSubModal"
        @done="subDone"
        :title="subTitle"
        :item="details"
        :editMode="subEditMode"
      />
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import ShippingZone from "@/components/Modals/Settings/ShippingZone.vue";

export default {
  components: { TableComponent, ShippingZone },
  data() {
    return {
      fields: [
        // {
        //   key: "name",
        //   label: "Name",
        // },

        {
          key: "lga",
          label: "LGA",
        },

        {
          key: "price",
          label: "Price",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "";
          },
        },

        {
          key: "actions",
          label: "",
        },
      ],
      id: this.$route.params.id,
      details: {},
      busy: false,
      zones: [],
      subDialogVisible: false,
      subTitle: "",
      subEditMode: false
    };
  },
  methods: {
    getData() {
      this.busy = true;
      this.$http
        .get("find-shipping-class/" + this.id)
        .then((res) => {
          let resPayload = res.data;
          this.details = resPayload.shipping_class;
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    getZones() {
      this.busy = true;
      this.$http
        .get("admin/shipping-zones/" + this.id)
        .then((res) => {
          let resPayload = res.data;
          this.zones = resPayload.shipping_zones;
          console.log(resPayload.shipping_zones);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    editSubRecord(e) {
      this.details = e;
      console.log(e, "hhyhe");
      this.subEditMode = true;
      this.subToggleModal();
    },

    getSubRecord(e) {
      this.$router.push(`/shipping-zones/${e.id}`);
    },

    getSubItem(value) {
      this.drawer = true;
      this.details = value;
    },

    closeModal() {
      this.dialogVisible = !this.dialogVisible;
      this.item = {};
      this.editMode = false;
      this.viewMode = false;
    },

    closeSubModal() {
      this.subDialogVisible = !this.subDialogVisible;
      this.subItem = {};
      this.subEditMode = false;
      this.subViewMode = false;
    },

    subComplete(value) {
      this.$http
        .post(`/admin/remove-shipping-zone/${value.id}`)
        .then((res) => {
          this.$swal.fire("Successful!", `${res.data.message}`, "success");
          this.getData();
          this.getZones();
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteSubRecord(value) {
      this.$swal
        .fire({
          title: "Continue?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.subComplete(value);
          }
        });
    },

    toggleModal() {
      if (this.editMode) {
        this.title = "Edit";
        this.dialogVisible = !this.dialogVisible;
      } else if (this.viewMode) {
        this.title = "View";
        this.dialogVisible = !this.dialogVisible;
      } else {
        this.title = "Create New";
        this.dialogVisible = !this.dialogVisible;
      }
    },

    subToggleModal() {
      console.log(this.subEditMode, "hhyheyhe");
      if (this.subEditMode) {
        this.subTitle = "Edit";
        this.subDialogVisible = !this.subDialogVisible;
      } else {
        this.subTitle = "Create New";
        this.subDialogVisible = !this.subDialogVisible;
      }
    },

    done() {
      this.closeModal();
      this.getData();
      this.getZones();
    },

    subDone() {
      this.getData();
      this.closeSubModal();
      this.getZones();
      this.drawer = false;
    },
  },

  mounted() {
    this.getData();
    this.getZones();
  },

  computed: {
    // details() {
    //   return this.$store.getters["shipping/getZones"];
    // },
  },
};
</script>

<style></style>
