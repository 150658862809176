<template>
  <div>
    <div>
      <div>
        <div class="mt-3">
          <div class="flex items-center space-x-4">
            <div class="video w-full h-full">
              <video
                controls
                :src="config.imgUrl + product.video"
                style="width: 100%; height: 330px"
              ></video>
            </div>

            <div class="images grid grid-cols-3 gap-4">
              <img
                :src="config.imgUrl + product.app_icon"
                alt=""
                class="object-cover rounded-lg object-center"
              />
              <img
                :src="config.imgUrl + product.photo_one"
                alt=""
                class="object-cover rounded-lg object-center"
              />
              <img
                :src="config.imgUrl + product.photo_two"
                alt=""
                class="object-cover rounded-lg object-center"
              />
              <img
                :src="config.imgUrl + product.photo_three"
                alt=""
                class="object-cover rounded-lg object-center"
              />
              <img
                :src="config.imgUrl + product.photo_four"
                alt=""
                class="object-cover rounded-lg object-center"
              />
              <img
                :src="config.imgUrl + product.photo_five"
                alt=""
                class="object-cover rounded-lg object-center"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import config from '@/config/api'
export default {
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      //    config
    };
  },
  computed: {
    // product(){
    //     return this.$store.getters['products/singleProduct']
    // }
  },
};
</script>
