import Transactions from "@/pages/Finances/transactions/IndexPage.vue";

import Payouts from "@/pages/Finances/payouts/IndexPage.vue"

import Withdrawals from "@/pages/Finances/withdrawals/IndexPage.vue"

import Payments from "@/pages/Finances/payments/IndexPage.vue"

import Orders from "@/pages/Finances/orders/IndexPage.vue"
import OrderDetails from "@/pages/Finances/orders/_uuid.vue"

const router = [
  {
    path: "/finance/transactions",
    name: "transactions",
    component: Transactions,
    meta: {
      layout: "AppDashboardLayout",
      parent: "finance",
      name: "transactions",
      header: "All transactions",
      requiresAuth: true,
      description:
        "All transactions.",
    },
  },

  {
    path: "/finance/orders",
    name: "orders",
    component: Orders,
    meta: {
      layout: "AppDashboardLayout",
      parent: "finance",
      name: "orders",
      header: "All orders",
      requiresAuth: true,
      description:
        "All orders.",
    },
  },

  {
    path: "/finance/order/:id",
    name: "order-details",
    component: OrderDetails,
    meta: {
      layout: "AppDashboardLayout",
      parent: "finance",
      name: "orders",
      header: "Single Order",
      requiresAuth: true,
      description:
        "View single order",
    },
  },

  {
    path: "/finance/payments",
    name: "payments",
    component: Payments,
    meta: {
      layout: "AppDashboardLayout",
      parent: "finance",
      name: "payments",
      header: "All payments",
      requiresAuth: true,
      description:
        "All payments.",
    },
  },

  {
    path: "/finance/payouts",
    name: "payouts",
    component: Payouts,
    meta: {
      layout: "AppDashboardLayout",
      parent: "finance",
      name: "payouts",
      header: "All payouts",
      requiresAuth: true,
      description:
        "All payouts.",
    },
  },

  {
    path: "/finance/withdrawals",
    name: "withdrawals",
    component: Withdrawals,
    meta: {
      layout: "AppDashboardLayout",
      parent: "finance",
      name: "withdrawals",
      header: "All Withdrawals",
      requiresAuth: true,
      description:
        "List of withdrawals from referrals.",
    },
  },

 
];

export default router;
