<template>
  <div class="">
    <div class="flex justify-between items-start">
      <div>
        <h5 class="font-bold">{{ details.name }}</h5>
        <h6 class="text-gray-600 bold-semibold">{{ details.lga }}</h6>
        <h5 class="text-gray-500 text-sm font-bold">{{ details.price | formatCurrency }}</h5>
      </div>
      <button @click="subToggleModal" class="warehauze-btn warehauze-primary">
        Add Shipping Range
      </button>
    </div>
    <hr class="my-4" />
    <h6 class="font-semibold mb-0">Shipping Ranges</h6>
    <table-component
      :items="ranges"
      :disableEditAction="true"
      :disableDeleteAction="false"
      :disableViewAction="true"
      :fields="fields"
      :busy="busy"
      @view="getSubRecord"
      @delete="deleteSubRecord"
      :paginate="false"
    >
    </table-component>

    <!-- Modal to Add or Edit SubCategories -->
    <div>
      <ShippingMethods
        :visible="subDialogVisible"
        @toggle="closeSubModal"
        @done="subDone"
        title="Create New"
        :item="details"
      />
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import ShippingMethods from "@/components/Modals/Settings/ShippingMethods.vue";

export default {
  components: { TableComponent, ShippingMethods },
  data() {
    return {
      fields: [
        {
          key: "min_quantity",
          // label: "Name",
        },

        {
          key: "max_quantity",
          // label: "LGA",
        },

        {
          key: "percentage",
          // label: "LGA",
        },

        //   {
        //     key: "price",
        //     label: "Price",
        //     formatter: (item) => {
        //       return item
        //         ? Number(item).toLocaleString("en-US", {
        //             style: "currency",
        //             currency: "NGN",
        //           })
        //         : "";
        //     },
        //   },

        {
          key: "actions",
          label: "",
        },
      ],
      id: this.$route.params.id,
      details: {},
      busy: false,
      ranges: [],
      subDialogVisible: false,
    };
  },
  methods: {
    getData() {
      this.busy = true;
      this.$http
        .get("find-shipping-zone/" + this.id)
        .then((res) => {
          let resPayload = res.data;
          this.details = resPayload.shipping_zone;
          this.ranges = resPayload.shipping_zone.range_pricings;
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    getRecord(e, value) {
      this.item = e;
      console.log(e, "hhyhe");
      if (value === "edit") {
        this.editMode = true;
        this.toggleModal();
      } else {
        this.$router.push("/shipping-class/" + e.id);
      }
    },

    getSubRecord(e) {
      this.$router.push(`/shipping-zones/${e.id}`);
    },

    getSubItem(value) {
      this.drawer = true;
      console.log(value, "kkkk");
      this.details = value;
    },

    closeModal() {
      this.dialogVisible = !this.dialogVisible;
      this.item = {};
      this.editMode = false;
      this.viewMode = false;
    },

    closeSubModal() {
      this.subDialogVisible = !this.subDialogVisible;
      this.subItem = {};
      this.subEditMode = false;
      this.subViewMode = false;
    },

    subComplete(value) {
      this.$http
        .post(`/admin/remove-range-pricing/${value.id}`)
        .then((res) => {
          this.$swal.fire("Successful!", `${res.data.message}`, "success");
          this.getData();
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteSubRecord(value) {
      this.$swal
        .fire({
          title: "Continue?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.subComplete(value);
          }
        });
    },

    toggleModal() {
      if (this.editMode) {
        this.title = "Edit";
        this.dialogVisible = !this.dialogVisible;
      } else if (this.viewMode) {
        this.title = "View";
        this.dialogVisible = !this.dialogVisible;
      } else {
        this.title = "Create New";
        this.dialogVisible = !this.dialogVisible;
      }
    },

    subToggleModal() {
      // if (this.subEditMode) {
      //   this.subTitle = "Edit";
      //   this.subDialogVisible = !this.subDialogVisible;
      // } else
      console.log(this.subViewMode, "hhyheyhe");
      if (this.subViewMode) {
        this.subTitle = "View";
        this.subDialogVisible = !this.subDialogVisible;
      } else {
        this.subTitle = "Create New";
        this.subDialogVisible = !this.subDialogVisible;
      }
    },

    done() {
      this.closeModal();
      this.getData();
    },

    subDone() {
      this.getData();
      this.closeSubModal();
      this.drawer = false;
    },
  },

  mounted() {
    this.getData();
  },

  computed: {
    // details() {
    //   return this.$store.getters["shipping/getZones"];
    // },
  },
};
</script>

<style></style>
