<template>
  <div>
    <!-- <div class="flex justify-end">
        <filter-tabs :tabs="tabs" @filter-changed="filter($event)" />
      </div> -->
    <div class="flex justify-between items-center mb-4">
      <div>
        <h5 class="font-bold">Coupons</h5>
        <!-- <h6 class="text-gray-600 bold-semibold">{{ details.lga }}</h6>
        <h5 class="text-gray-500 text-sm font-bold">{{ details.price | formatCurrency }}</h5> -->
      </div>
      <button @click="openModal" class="warehauze-btn warehauze-primary">
        Generate Coupon
      </button>
    </div>

    <div>
      <table-component
        :items="items"
        :fields="fields"
        @delete="disable"
        :busy="busy"
        @view="view"
        @next="filter($event)"
        :meta="meta"
        @edit="edit"
        deleteTitle="You are about to disable this coupon code!, this action is irreversible"
      />
    </div>

    <!-- View Receipt -->
    <view-receipts
      :visible="dialogVisible"
      @toggle="close"
      :date="item.created_at"
      title="Code Details"
      :displayData="displayData"
      :hasAction="false"
      :display-key="[
        'code',
        'discount_percentage',
        'start_date',
        'end_date',
        'shop',
        'status',
      ]"
      @payoutsFunc="payoutsFunc($event)"
      @copy="copyAccountNumber"
    />

    <create-promo-code
      :visible="visibleCreate"
      :data='item'
      :editMode="editMode"
      @done="filter"
      @toggle="visibleCreate = false"
    />
  </div>
</template>

<script>
// import FilterTabs from "@/components/Utils/FilterTabs.vue";
import TableComponent from "@/components/TableComponent.vue";
import ViewReceipts from "@/components/Finance/ViewReceipts.vue";
import CreatePromoCode from "@/components/Modals/CreatePromoCode.vue";
export default {
  components: {
    // FilterTabs,
    TableComponent,
    ViewReceipts,
    CreatePromoCode,
  },
  data() {
    return {
      tabs: [
        {
          title: "All Payments",
          label: "",
        },
        {
          title: "Fiat Payments",
          label: "flutterwave",
        },
        {
          title: "Crypto Payments",
          label: "cryptocurrency",
        },
      ],
      items: [],
      fields: [
        {
          key: "code",
          label: "Code",
        },
        {
          key: "discount_percentage",
          label: "Discount Percentage",
        },
        //   {
        //     key: "amount",
        //     label: "Amount",
        //     formatter: (item) => {
        //       return item
        //         ? Number(item).toLocaleString("en-US", {
        //             style: "currency",
        //             currency: "NGN",
        //           })
        //         : "";
        //     },
        //   },
        {
          key: "start_date",
          label: "Start Date",
        },
        {
          key: "end_date",
          label: "End Date",
          formatter: (item) => {
            return item ? item : "---";
          },
        },
        {
          key: "status",
          label: "Status",
        },

        {
          key: "shop",
          label: "Shop",
          formatter: (item) => {
            return item ? item.name : "---";
          },
        },

        {
          key: "actions",
          label: "",
        },
      ],
      busy: false,
      dialogVisible: false,
      displayData: {
        code: "",
        discount_percentage: "",
        start_date: "",
        end_date: "",
        shop: "",
        status: "",
      },
      item: {},
      meta: {},
      ID: "",
      visibleCreate: false,
      editMode: false
    };
  },

  methods: {
    openModal() {
      this.visibleCreate = true;
    },

    filter(page = 1) {
      this.busy = true;
      this.$http
        .get(`admin/promo-codes?page=${page}`)
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload.data;
          this.meta = resPayload;
          this.visibleCreate = false;
          this.editMode = false
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    copyAccountNumber() {
      navigator.clipboard.writeText(this.displayData.account_number).then(
        (success) => {
          alert("copied!"), console.log(success);
        },
        (err) => console.log("error", err)
      );
    },

    edit(e){
      this.item = e
      this.visibleCreate = true
      this.editMode = true
      return
    },

    view(e) {
      console.log(e)
      this.item = e;
      this.dialogVisible = true;
    },

    payoutsFunc(e) {
      let payload = {
        status: e,
      };
      console.log(e, this.ID);
      this.$http
        .post(`admin/withdrawals/${this.ID.id}`, payload)
        .then((res) => {
          let resPayload = res.data;
          console.log(resPayload, "ommmo");
          this.view(this.ID);
          this.filter();
          this.$swal.fire("Done", resPayload.message, "success");
        })
        .catch((err) => {
          console.log(err);
          this.$swal.fire("Error", "Not completed", "warnning");
        });
    },

    disable(value) {
      console.log(value);
      
      this.$http
        .post(`promo-codes/${value.id}/disable`)
        .then((res) => {
          this.$swal.fire(
            "Disabled!",
            "Coupon Disabled succesfully.",
            "success"
          );
          this.filter();
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // disable(value) {
    //   this.$swal
    //     .fire({
    //       title: "Continue?",
    //       text: "You are about to disable this coupon code!, this action is irreversible",
    //       icon: "warning",
    //       showCancelButton: true,
    //       confirmButtonText: "Yes, disabled it!",
    //     })
    //     .then((result) => {
    //       console.log(result, "kkk");
    //       if (result.isConfirmed) {
    //         this.complete(value);
    //       }
    //     });
    // },

    close() {
      this.dialogVisible = !this.dialogVisible;
    },
  },

  beforeMount() {
    let value = "";
    this.filter(value);
  },

  watch: {
    item: {
      handler(val) {
        this.displayData = {
          code: val.code,
          discount_percentage: val.discount_percentage,
          start_date: val.start_date,
          end_date: val.end_date,
          shop: val.shop ? val.shop.name : null,
          status: val.status,
        };
      },
      immediate: false,
    },
  },
};
</script>

<style></style>
