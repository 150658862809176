<template>
  <div>
    <div class="flex justify-end mb-4">
      <filter-tabs :tabs="tabs" @filter-changed="setChannel($event)" />
    </div>
    <div>
      <table-component
        :items="items"
        :fields="fields"
        :disableDeleteAction="true"
        :disableEditAction="true"
        :busy="busy"
        @view="view"
        @next="filter($event)"
        :meta="meta"
      />
    </div>

    <!-- View Receipt -->
    <view-receipts
      :visible="dialogVisible"
      @toggle="close"
      :date="item.created_at"
      title="Payment Details"
      :displayData="displayData"
      :data="item"
      :display-key="[
        'customer_name',
        'channel',
        'amount',
        'reference_number',
        'transaction_reference',
        'status',
        'description',
      ]"
    />
  </div>
</template>

<script>
import FilterTabs from "@/components/Utils/FilterTabs.vue";
import TableComponent from "@/components/TableComponent.vue";
import ViewReceipts from "@/components/Finance/ViewReceipts.vue";
export default {
  components: { FilterTabs, TableComponent, ViewReceipts },
  data() {
    return {
      tabs: [
        {
          title: "All Payments",
          label: "",
        },
        {
          title: "Fiat Payments",
          label: "flutterwave",
        },
        {
          title: "Crypto Payments",
          label: "cryptocurrency",
        },
      ],
      items: [],
      fields: [
        {
          key: "tx_ref",
          label: "Transaction Ref",
          formatter: (val) => {
            return val === null ? "---" : val
          }
        },
        {
          key: "tx_amount",
          label: "Amount",
        },
        {
          key: "channel",
          label: "Channel",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "created_at",
          label: "Created At",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      busy: false,
      dialogVisible: false,
      displayData: {
        customer_name: "",
        channel: "",
        amount: "",
        reference_number: "",
        transaction_reference: "",
        status: "",
        description: "",
      },
      item: {},
      meta: {},
      channel: ''
    };
  },

  methods: {
    setChannel(e) {
      this.channel = e
      this.filter()
    },

    filter(page = 1) {
      this.busy = true;
      this.$http
        .get(`admin/get-payments?channel=${this.channel}&page=${page}`)
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload.data;
          console.log(resPayload, "ommomo");
          this.meta = resPayload
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    view(e) {
      console.log(e, "kkkk");
      this.$http
        .get(`admin/find-payment/${e.id}`)
        .then((res) => {
          let resPayload = res.data;
          console.log(resPayload, "ommmo");
          this.item = resPayload.payment;
          this.dialogVisible = !this.dialogVisible;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    close() {
      this.dialogVisible = !this.dialogVisible;
    },
  },

  beforeMount() {
    let value = "";
    this.filter(value);
  },

  watch: {
    item: {
      handler(val) {
        if (val.user) {
          this.displayData = {
            customer_name: val.name,
            channel: val.channel,
            amount: `${Number(val.amount).toLocaleString()} ${val.currency}`,
            reference_number: val.ref_no,
            transaction_reference: val.tx_ref,
            status: val.status,
            description: val.description,
          };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
