<template>
  <div class="mt-4">
    <div class="flex justify-end mb-4">
      <filter-tabs :tabs="tabs" @filter-changed="setChannel($event)" />
    </div>
    <table-component
      :items="items"
      :busy="busy"
      :disableEditAction="true"
      :disableDeleteAction="true"
      :fields="fields"
      @view="view($event)"
      :paginate="true"
      :meta="meta"
      @next="filter($event)"
    >
    </table-component>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import FilterTabs from "@/components/Utils/FilterTabs.vue";
export default {
  components: { TableComponent, FilterTabs },
  data() {
    return {
      items: [],
      busy: false,
      tabs: [
        {
          title: "All",
          label: "",
        },
        {
          title: "Pending",
          label: "pending",
        },
        {
          title: "Approved",
          label: "approved",
        },
        {
          title: "Rejected",
          label: "rejected",
        },
      ],
      fields: [
        {
          key: "beneficiary",
          label: "Beneficiary",
        },

        {
          key: "is_registered",
          label: "Business Registration",
        },

        {
          key: "status",
          label: "Status",
        },

        {
          key: "created_at",
          label: "Date Created",
        },

        {
          key: "actions",
          label: "",
        },
      ],
      meta: {},
      channel: ""
    };
  },

  methods: {
    setChannel(e) {
      this.channel = e
      this.filter()
    },
     
    filter(page = 1) {
      this.busy = true;
      this.$http
        .get(`admin/get-vendor-applications?status=${this.channel}&page=${page}`)
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload.data;
          this.meta = resPayload
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    getRecord(e, value) {
      this.item = e;
      console.log(e, "hhyhe");
      if (value === "edit") {
        this.editMode = true;
        this.toggleModal();
      } else {
        this.viewMode = true;
        this.toggleModal();
      }
    },

    closeModal() {
      this.item = {};
      this.dialogVisible = !this.dialogVisible;
      this.editMode = false;
      this.viewMode = false;
    },

    complete(value) {
      this.$http
        .post(`/admin/delete-tag/${value.id}`)
        .then((res) => {
          this.$swal.fire("Successful!", `${res.data.message}`, "success");
          this.getData();
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteRecord(value) {
      this.$swal
        .fire({
          title: "Continue?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.complete(value);
          }
        });
    },

    toggleModal() {
      if (this.editMode) {
        this.title = "Edit";
        this.dialogVisible = !this.dialogVisible;
      }
      else if (this.viewMode) {
        this.title = "View";
        this.dialogVisible = !this.dialogVisible;
      } else {
        this.title = "Create New";
        this.dialogVisible = !this.dialogVisible;
      }
    },

    done() {
      this.closeModal();
      this.getData();
    },

    view(value) {
      this.$router.push(`/vendor-kyc/${value.id}`);
    },
  },

  mounted() {
    this.filter();
  },
};
</script>

<style></style>
