<template>
  <div>
    <div v-if="busy" class="flex flex-col gap-3">
      <div class="p-4">
        <span class="mt-2">
          <b-skeleton
            width="100%"
            v-for="item in 4"
            :key="item"
            height="20px"
          ></b-skeleton>
        </span>
      </div>
    </div>
    <span v-else class="flex flex-col gap-3 p-4">
      <div class="flex gap-2">
        <select name="" id="" class="input w-full text-xs" v-model="user_id">
          <option value="" selected disabled>--Select One--</option>
          <option v-for="item in nonRoleUsers" :key="item?.id" :value="item?.id">
            {{ `${item?.first_name} ${item.last_name}` }}
          </option>
        </select>
        <button
          @click="assignUser"
          class="warehauze-btn warehauze-primary w-fit block whitespace-nowrap ml-auto"
        >
          Add User
        </button>
      </div>
      <span
        class="text-sm even:bg-gray-100 p-2 flex items-center justify-between"
        role="button"
        v-for="(item, idx) in users"
        :key="idx"
      >
        <span class="flex items-center gap-2">
          <i-icon icon="uil:user" />
          {{ `${item?.first_name} ${item?.last_name}` }}
        </span>
        <span class="text-red-600" role="button" @click="removeUser(item)">
          <i-icon icon="fluent:delete-16-filled" />
        </span>
      </span>
      <span
        v-if="!busy && users.length === 0"
        class="block mx-auto block flex flex-col gap-1 items-center"
      >
        <i-icon icon="majesticons:user-line" class="text-4xl" />
        <span class="text-sm">No user assigned to role</span>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    ID: Number,
  },
  data() {
    return {
      users: [],
      nonRoleUsers: [],
      busy: false,
      selected: "",
      user_id: ""
    };
  },
  methods: {
    list() {
      this.busy = true;
      this.$http
        .get(`admin/roles/${this.ID}/role-users`)
        .then((res) => {
          let resPayload = res.data;
          console.log(resPayload);
          this.users = resPayload;
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    removeUser(item) {
      console.log(item)
      let payload = {
        userId: item.id,
      }
      this.$http
        .post(`admin/roles/remove-user-from-role`, payload)
        .then(() => {
          this.list()
          this.listNonRoleUsers()
          this.$swal.fire(
            "Completed!",
            "USer removed from role",
            "success"
          );
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        })
    },

    assignUser() {
      let payload = {
        userId: this.user_id,
        roleId: this.ID
      }
      this.$http
        .post(`admin/roles/${this.ID}/change-user-role`, payload)
        .then(() => {
          this.list()
          this.listNonRoleUsers()
          this.$swal.fire(
            "Completed!",
            "User assigned to role.",
            "success"
          );
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        })
        .finally(()=> {
          this.user_id = ""
        })
    },

    listNonRoleUsers() {
      this.$http.get(`admin/roles/${this.ID}/not-role-users`).then((res) => {
        console.log(res);
        this.nonRoleUsers = res.data;
      });
    },
  },

  beforeMount() {
    this.list();
    this.listNonRoleUsers();
  },

  watch: {
    ID: {
      handler(val) {
        if (val) {
          this.list();
          this.listNonRoleUsers();
        }
      },
    },
  },
};
</script>

<style></style>
