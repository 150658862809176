var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogVisible,"before-close":_vm.handleClose,"width":"30%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"mt-6"},[_c('div',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid, handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"mt-3 flex gap-4"},[_c('validation-provider',{attrs:{"name":"Minimum quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ dirty, invalid, errors }){return [_c('label',{attrs:{"for":"percentage"}},[_vm._v("Minimum Quantity")]),_c('div',{staticClass:"input-field",class:{
                    error: dirty && invalid,
                  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataObj.min),expression:"dataObj.min"}],attrs:{"type":"text","name":"text","id":"name","placeholder":"Enter Minimum Quantity"},domProps:{"value":(_vm.dataObj.min)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.dataObj, "min", $event.target.value)}}})]),(errors)?_c('span',{staticClass:"text-xs block text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_vm._l((_vm.validationErrors.min_quantity),function(error){return _c('span',{key:error,staticClass:"mb-5 block text-xs text-red-600"},[_vm._v(_vm._s(error))])})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"maximum quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ dirty, invalid, errors }){return [_c('label',{attrs:{"for":"max"}},[_vm._v("Maximum Quantity")]),_c('div',{staticClass:"input-field",class:{
                    error: dirty && invalid,
                  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataObj.max),expression:"dataObj.max"}],attrs:{"type":"text","name":"text","id":"name","placeholder":"Enter Maximum Quantity"},domProps:{"value":(_vm.dataObj.max)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.dataObj, "max", $event.target.value)}}})]),(errors)?_c('span',{staticClass:"text-xs block text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_vm._l((_vm.validationErrors.max_quantiy),function(error){return _c('span',{key:error,staticClass:"mb-5 block text-xs text-red-600"},[_vm._v(_vm._s(error))])})]}}],null,true)})],1),_c('div',{staticClass:"mt-3"},[_c('validation-provider',{attrs:{"name":"percentage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ dirty, invalid, errors }){return [_c('label',{attrs:{"for":"percentage"}},[_vm._v("Percentage")]),_c('div',{staticClass:"input-field",class:{
                    error: dirty && invalid,
                  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataObj.percentage),expression:"dataObj.percentage"}],attrs:{"type":"text","name":"text","id":"name","placeholder":"Percentage"},domProps:{"value":(_vm.dataObj.percentage)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.dataObj, "percentage", $event.target.value)}}})]),(errors)?_c('span',{staticClass:"text-xs block text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_vm._l((_vm.validationErrors.percentage),function(error){return _c('span',{key:error,staticClass:"mb-5 block text-xs text-red-600"},[_vm._v(_vm._s(error))])})]}}],null,true)})],1),(!_vm.viewMode)?_c('div',{staticClass:"mt-4"},[_c('button',{staticClass:"warehauze-btn warehauze-primary w-100",class:{ 'bg-gray400': invalid, 'bg-gray400': _vm.busy },attrs:{"disabled":invalid}},[_c('span',[_vm._v(_vm._s(_vm.editMode ? "Update" : "Create"))])])]):_vm._e()])]}}])})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }