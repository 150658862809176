<template>
  <div>
    <table-component
      :items="items"
      :busy="busy"
      :disableEditAction="false"
      :disableDeleteAction="false"
      :fields="fields"
      @view="getRecord($event, 'view')"
      @delete="deleteRecord"
      @edit="getRecord($event, 'edit')"
      :paginate="false"
    >
      <template #button>
        <button
          @click="dialogVisible = !dialogVisible"
          class="warehauze-btn warehauze-primary"
        >
          Add Tag
        </button>
      </template>
    </table-component>

    <!-- Modal to Add or Edit Tags -->
    <div>
      <tags-modal
        :visible="dialogVisible"
        @toggle="closeModal"
        @done="done"
        :title="title"
        :editMode="editMode"
        :viewMode="viewMode"
        :data="item"
      />
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import TagsModal from "../Modals/Settings/TagsModal.vue";

export default {
  components: { TableComponent, TagsModal },
  data() {
    return {
      items: [],
      item: {},
      title: "",
      busy: false,
      dialogVisible: false,
      editMode: false,
      viewMode: false,
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "slug",
          label: "Slug",
        },
        {
          key: "created_at",
          label: "Date Created",
        },

        {
          key: "actions",
          label: "",
        },
      ],
    };
  },

  methods: {
    getData() {
      this.busy = true;
      this.$http
        .get("all-tags")
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload.tags;
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    getRecord(e, value) {
      this.item = e;
      console.log(e, "hhyhe");
      if (value === "edit") {
        this.editMode = true;
        this.toggleModal();
      } else {
        this.viewMode = true;
        this.toggleModal();
      }
    },

    closeModal() {
      this.item = {};
      this.dialogVisible = !this.dialogVisible;
      this.editMode = false;
      this.viewMode = false;
    },

    complete(value) {
      this.$http
        .post(`/admin/delete-tag/${value.id}`)
        .then((res) => {
          this.$swal.fire("Successful!", `${res.data.message}`, "success");
          this.getData();
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteRecord(value) {
      this.$swal
        .fire({
          title: "Continue?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.complete(value);
          }
        });
    },

    toggleModal() {
      if (this.editMode) {
        this.title = "Edit";
        this.dialogVisible = !this.dialogVisible;
      }
      else if (this.viewMode) {
        this.title = "View";
        this.dialogVisible = !this.dialogVisible;
      } else {
        this.title = "Create New";
        this.dialogVisible = !this.dialogVisible;
      }
    },

    done() {
      this.closeModal();
      this.getData();
    },

    viewRecord(value) {
      this.$router.push(`store/${value.id}`);
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style></style>
