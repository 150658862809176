var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-4"},[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"grid grid-cols-5 gap-4"},_vm._l((5),function(item){return _c('b-skeleton',{key:item,attrs:{"width":"100%","height":"80px"}})}),1)]},proxy:true}])},[_c('div',{staticClass:"flex gap-4"},_vm._l((_vm.cardsData),function(value,name){return _c('div',{key:name,staticClass:"w-full bg-gradient-to-r p-3 rounded-lg",class:{
          'from-red-400 to-red-500': name === 'total_products',
          'from-green-400 to-green-500': name === 'total_payments',
          'from-amber-400 to-amber-500': name === 'total_orders',
          'from-cyan-400 to-cyan-500': name === 'total_users',
          'from-slate-400 to-slate-500': name === 'total_shops',
        }},[_c('span',{staticClass:"text-xs font-semibold uppercase"},[_vm._v(_vm._s(name.split("_").join(" ")))]),_c('div',{staticClass:"font-bold text-2xl"},[_vm._t(name,function(){return [_vm._v(_vm._s(value ? value : "0")+" ")]},{"value":value})],2)])}),0)]),_c('div',{staticClass:"flex gap-4"},[_c('div',{staticClass:"bg-gray-100 p-4 rounded-lg w-full"},[_c('h6',{staticClass:"font-semibold"},[_vm._v("Products")]),_c('hr',{staticClass:"my-2"}),_c('div',{staticClass:"flex flex-col gap-3"},_vm._l((_vm.productsData),function(value,name){return _c('div',{key:name},[_c('div',{staticClass:"flex gap-1 items-center"},[_c('h6',{staticClass:"text-xs font-medium"},[_vm._v(" "+_vm._s(name.split("_").join(" "))+" ")]),_c('h6',[_vm._v(_vm._s(value))])]),_c('span',{staticClass:"block rounded-lg h-3",class:{
              'bg-amber-100': name === 'pending_products',
              'bg-red-100': name === 'rejected_products',
              'bg-blue-100': name === 'approved_products',
            },style:({
              width:
                `${_vm.getPercentage(
                  _vm.analytics.products_total,
                  _vm.analytics.products_total
                )}` + '%',
            })},[_c('span',{staticClass:"block rounded-lg h-3",class:{
                'bg-amber-500': name === 'pending_products',
                'bg-red-500': name === 'rejected_products',
                'bg-blue-500': name === 'approved_products',
              },style:({
                width:
                  `${_vm.getPercentage(value, _vm.analytics.products_total)}` + '%',
              })})])])}),0)]),_c('div',{staticClass:"bg-gray-100 p-4 rounded-lg w-full"},[_c('h6',{staticClass:"font-semibold"},[_vm._v("Shops")]),_c('hr',{staticClass:"my-2"}),_c('div',{staticClass:"flex flex-col gap-3"},_vm._l((_vm.shopsData),function(value,name){return _c('div',{key:name},[_c('div',{staticClass:"flex gap-1 items-center"},[_c('h6',{staticClass:"text-xs font-medium"},[_vm._v(" "+_vm._s(name.split("_").join(" "))+" ")]),_c('h6',[_vm._v(_vm._s(value))])]),_c('span',{staticClass:"block rounded-lg h-3",class:{
              'bg-red-100': name === 'deactivated_shops',
              'bg-green-100': name === 'activated_shops',
            },style:({
              width:
                `${_vm.getPercentage(
                  _vm.analytics.shops_total,
                  _vm.analytics.shops_total
                )}` + '%',
            })},[_c('span',{staticClass:"block rounded-lg h-3",class:{
                'bg-red-500': name === 'deactivated_shops',
                'bg-green-500': name === 'activated_shops',
              },style:({
                width:
                  `${_vm.getPercentage(value, _vm.analytics.shops_total)}` + '%',
              })})])])}),0)]),_c('div',{staticClass:"bg-gray-100 p-4 rounded-lg w-full"},[_c('h6',{staticClass:"font-semibold"},[_vm._v("Orders")]),_c('hr',{staticClass:"my-2"}),_c('div',{staticClass:"flex flex-col gap-3"},_vm._l((_vm.ordersData),function(value,name){return _c('div',{key:name},[_c('div',{staticClass:"flex gap-1 items-center"},[_c('h6',{staticClass:"text-xs font-medium"},[_vm._v(" "+_vm._s(name.split("_").join(" "))+" ")]),_c('h6',[_vm._v(_vm._s(value))])]),_c('span',{staticClass:"block rounded-lg h-3",class:{
              'bg-amber-100': name === 'pending_orders',
              'bg-green-100': name === 'delivered_orders',
              'bg-cyan-100': name === 'in_progress_orders',
              'bg-red-100': name === 'canceled_orders',
              'bg-blue-100': name === 'completed_orders',
            },style:({
              width:
                `${_vm.getPercentage(
                  _vm.analytics.orders_total,
                  _vm.analytics.orders_total
                )}` + '%',
            })},[_c('span',{staticClass:"block rounded-lg h-3",class:{
                'bg-amber-500': name === 'pending_orders',
                'bg-green-500': name === 'delivered_orders',
                'bg-cyan-500': name === 'in_progress_orders',
                'bg-red-500': name === 'canceled_orders',
                'bg-blue-500': name === 'completed_orders',
              },style:({
                width:
                  `${_vm.getPercentage(value, _vm.analytics.orders_total)}` + '%',
              })})])])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }