<template>
  <div class="bg-white p-6 rounded-lg">
    <!-- <div class="flex justify-between items-center">
        <h5 v-if="busy">Retrieving Data ...</h5>
        <h5 class="font-bold">{{ details.name }}</h5>
        <button @click="subToggleModal" class="warehauze-btn warehauze-primary">
          Add Shipping Zone
        </button>
      </div>
      <hr class="my-4" /> -->

    <h5 class="font-semibold mb-0">Referral Bonus Settings</h5>
    <!-- <div v-if="busy">
        <span class="text-sm">Retrieving Shipping Zones ...</span>
      </div> -->
    <div class="mt-4 flex flex-col gap-6">
      <div>
        <label for="">Bonus Amount</label>
        <div class="flex gap-2">
          <input
            type="text"
            v-model="bonus"
            class="input"
            placeholder="Amount"
          />
          <button
            class="warehauze-btn warehauze-primary whitespace-nowrap"
            @click="referralFunc('credit')"
          >
            {{ bonusAmount ? "Update Amount" : "Add Bonus Amount" }}
          </button>
          <button
            v-if="bonusAmount"
            class="warehauze-btn warehauze-primary whitespace-nowrap"
            @click="deleteSubRecord('credit')"
          >
            Delete
          </button>
        </div>
      </div>

      <div>
        <label for="">Withdrawal Limit</label>
        <div class="flex gap-2">
          <input
            type="text"
            v-model="limit"
            class="input"
            placeholder="Amount"
          />
          <button
            class="warehauze-btn warehauze-primary whitespace-nowrap"
            @click="referralFunc('withdraw')"
          >
            {{ withdrawalLimit ? "Update Limit" : "Add Withdrawal Limit" }}
          </button>
          <button
            v-if="withdrawalLimit"
            class="warehauze-btn warehauze-primary whitespace-nowrap"
            @click="deleteSubRecord('withdraw')"
          >
            Delete
          </button>
        </div>
      </div>

      <!-- <div v-else>
          <span class="text-red-500 text-sm">No Shipping Zone Found</span>
        </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        // {
        //   key: "name",
        //   label: "Name",
        // },

        {
          key: "lga",
          label: "LGA",
        },

        {
          key: "price",
          label: "Price",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "";
          },
        },

        {
          key: "actions",
          label: "",
        },
      ],
      id: this.$route.params.id,
      details: {},
      busy: false,
      items: [],
      subDialogVisible: false,
      subTitle: "",
      subEditMode: false,
      bonusAmount: null,
      bonus: "",
      withdrawalLimit: null,
      limit: "",
    };
  },
  methods: {
    getSettings() {
      this.busy = true;
      this.$http
        .get("admin/referral-bonus")
        .then((res) => {
          let resPayload = res.data.bonuses;
          this.bonusAmount = resPayload.find((item) => item.type == "credit");
          this.withdrawalLimit = resPayload.find(
            (item) => item.type == "withdraw"
          );
          console.log(resPayload);
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    referralFunc(e) {
      if (!this.withdrawalLimit) {
        this.create(e);
        return;
      }
      if (!this.bonusAmount) {
        this.create(e);
        return;
      }
      this.update(e);
    },

    update(e) {
      let payload = {
        amount: e === "withdraw" ? this.limit : this.bonus,
      };
      this.busy = true;
      let ID = e === "withdraw" ? this.withdrawalLimit.id : this.bonusAmount.id;
      this.$http
        .post(`admin/referral-bonus/${ID}/update`, payload)
        .then((res) => {
          console.log(res);
          this.getSettings();
          this.$swal.fire("Done", res.data.message, "success");
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    deleteRecord(e) {
      //   let payload = {
      //     type: e,
      //     amount: e === "withdraw" ? this.limit : this.bonus,
      //   };
      this.busy = true;
      let ID = e === "withdraw" ? this.withdrawalLimit.id : this.bonusAmount.id;
      this.$http
        .post(`admin/referral-bonus/${ID}/delete`)
        .then((res) => {
          console.log(res);
          this.$swal.fire("Done", res.data.message, "success");
          this.getSettings();
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    create(e) {
      let payload = {
        type: e,
        amount: e === "withdraw" ? this.limit : this.bonus,
      };
      this.busy = true;
      this.$http
        .post("admin/referral-bonus/add", payload)
        .then((res) => {
          console.log(res);
          this.$swal.fire("Done", res.data.message, "success");
          this.getSettings();
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    deleteSubRecord(e) {
      this.$swal
        .fire({
          title: "Continue?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteRecord(e);
          }
        });
    },
  },

  watch: {
    bonusAmount: {
      handler(val) {
        if (val) {
          this.bonus = val.amount;
        } else {
          this.bonus = "";
        }
      },
    },

    withdrawalLimit: {
      handler(val) {
        if (val) {
          this.limit = val.amount;
        } else {
          this.limit = "";
        }
      },
    },
  },

  mounted() {
    this.getSettings();
  },

  computed: {
    // details() {
    //   return this.$store.getters["shipping/getZones"];
    // },
  },
};
</script>

<style></style>
