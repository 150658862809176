<template>
  <div>
    <!-- List of Mailing Lists  -->
    <div>
      <button
        @click="openDisplay"
        class="warehauze-btn warehauze-primary text-white flex items-center gap-2 ml-auto"
      >
        <span>Add Email</span>
      </button>
      <table-component
        :items="items"
        :meta="meta"
        :fields="fields"
        :busy="loading"
        @next="mailingList($event)"
        @delete="deleteRecord"
        @edit="getRecord"
        :editCell="editCell"
        :disableViewAction="true"
        @complete="completeEditRecord"
       
      />
    </div>

    <!-- Modal To Create Categories -->
    <el-dialog
      title="Add Email"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="40%"
    >
      <div class="mt-6">
        <validation-observer v-slot="{ invalid, handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div class="flex flex-col gap-3">
              <div v-for="(item, idx) in form.emailList" :key="idx">
                <div class="flex gap-4 items-center">
                  <input
                    type="email"
                    name="text"
                    id="name"
                    v-model="item.email"
                    placeholder="Enter Email"
                  />
                  <span
                    role="button"
                    @click="add"
                    v-if="idx + 1 === form.emailList.length"
                    class="text-green-600"
                  >
                    <i-icon icon="zondicons:add-solid" />
                  </span>
                  <span
                    @click="remove(idx)"
                    v-if="idx + 1 > 1"
                    role="button"
                    class="text-red-600"
                  >
                    <i-icon icon="zondicons:minus-solid" />
                  </span>
                </div>
              </div>
            </div>
            <div class="flex-flex-row">
              <span
                class="text-xs text-red-600"
                v-for="(val, i) in emailErrors"
                :key="i"
                >{{ val.error }}</span
              >
            </div>

            <div class="mt-4">
              <label for="">Mailing Category</label>
              <select name="" id="" v-model="form.mailing_category_id">
                <option selected disabled value="">--Select One--</option>
                <option
                  :value="item.id"
                  v-for="(item, idx) in categories"
                  :key="idx"
                >
                  {{ item.name }}
                </option>
              </select>
              <span
                class="text-xs text-red-600"
                v-for="item in validationErrors.mailing_category_id"
                :key="item"
              >
                {{ item }}</span
              >
            </div>

            <div class="mt-4">
              <button
                class="warehauze-btn warehauze-primary w-100"
                v-bind:disabled="invalid"
                :class="{ 'bg-gray400': invalid, 'bg-gray400': busy }"
              >
                <span>{{ editMode ? "Update" : "Create" }}</span>
              </button>
            </div>
          </form>
        </validation-observer>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableComponent from "../TableComponent.vue";
export default {
  components: { TableComponent },
  data() {
    return {
      items: [],
      categories: [],
      dialogVisible: false,
      fields: [
        {
          key: "editableEmail",
          label: "Email",
        },
        {
          key: "mailing_category.name",
          label: "Category",
        },
        {
          key: "created_at",
          label: "Created At",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      meta: {},
      form: {
        emailList: [
          {
            email: null,
          },
        ],
        mailing_category_id: "",
      },
      validationErrors: {},
      editMode: false,
      busy: false,
      loading: false,
      emailErrors: [],
      editCell: null,
    };
  },
  methods: {
    async onSubmit() {
      this.busy = true;
      this.validationErrors = {};
      this.emailErrors = [];
      let formData = new FormData();
      formData.append("mailing_category_id", this.form.mailing_category_id);
      this.form.emailList.forEach((element) => {
        formData.append("email[]", element.email);
      });
      try {
        let res = await this.$http.post(
          `admin/mailing-list/add-bulk`,
          formData
        );
        console.log(res);
        this.dialogVisible = false;
        this.$toastify({
          text: res.data.message,
          className: "info",
          style: {
            background: "#333",
            fontSize: "11px",
            borderRadius: "3px",
          },
        }).showToast();
        this.mailingList();
      } catch (error) {
        console.log(error);
        this.validationErrors = error.data.errors;
        let valErrors = error.data.errors;
        for (let key in valErrors) {
          console.log(key, "hello");

          valErrors[key].forEach((element) => {
            console.log(element, "kkkkk");
            if (key.includes("email")) {
              this.emailErrors.push({
                error: element,
              });
              console.log(this.emailErrors, "ommmo");
            }
          });
        }
      } finally {
        this.busy = false;
      }
    },

    async mailingList() {
      this.loading = true;
      try {
        let res = await this.$http.get(`admin/mailing-list`);
        console.log(res);
        let resPayload = res.data.mailingLists;
        this.items = resPayload.data;
        this.meta = resPayload
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async categoriesList() {
      try {
        let res = await this.$http.get(`admin/mailing-categories`);
        console.log(res);
        this.categories = res.data.mailingCategories;
      } catch (error) {
        console.log(error);
      }
    },

    async deleteRecord(e) {
      try {
        let res = await this.$http.post(`admin/mailing-list/delete/${e.id}`);
        console.log(res);
        this.$toastify({
          text: `${e.email} deleted from mailing list succesfully`,
          className: "info",
          style: {
            background: "#333",
            fontSize: "11px",
            borderRadius: "3px",
          },
        }).showToast();
        this.mailingList();
      } catch (error) {
        console.log(error);
      } finally {
        this.deleteLoading = null;
      }
    },

    view(e) {
      console.log(e);
    },

    async completeEditRecord(e) {
      console.log(e);
      // this.busy = true;
      let formData = new FormData();
      formData.append("mailing_category_id", e.mailing_category_id);
        formData.append("email", e.email);
      try {
        let res = await this.$http.post(
          `admin/mailing-list/update/${e.id}`,
          formData
        );
        console.log(res);
        this.dialogVisible = false;
        this.$toastify({
          text: res.data.message,
          className: "info",
          style: {
            background: "#333",
            fontSize: "11px",
            borderRadius: "3px",
          },
        }).showToast();
        this.editCell === null
        this.mailingList();
      } catch (error) {
        console.log(error);
        this.validationErrors = error.data.errors;
        let valErrors = error.data.errors;
        for (let key in valErrors) {
          console.log(key, "hello");

          valErrors[key].forEach((element) => {
            console.log(element, "kkkkk");
            if (key.includes("email")) {
              this.emailErrors.push({
                error: element,
              });
              console.log(this.emailErrors, "ommmo");
            }
          });
        }
      } finally {
        this.busy = false;
      }
    },

    getRecord(e) {
      console.log(e);
      this.editCell = this.editCell === null ? String(e.id) : null;
    },

    add() {
      this.form.emailList.push({
        email: null,
      });
    },

    remove(e) {
      this.form.emailList.splice(e, 1);
    },

    handleClose() {
      this.dialogVisible = !this.dialogVisible;
      this.form = {
        emailList: [
          {
            email: null,
          },
        ],
        mailing_category_id: "",
      };
      this.validationErrors = {};
      this.emailErrors = [];
    },

    openDisplay() {
      this.dialogVisible = !this.dialogVisible;
    },
  },

  beforeMount() {
    this.mailingList();
    this.categoriesList();
  },
};
</script>

<style></style>
