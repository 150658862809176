<template>
  <div>
    <div class="flex items-center space-x-2">
      <span
        class="back-button warehauze-btn warehauze-primary"
        role="button"
        @click="$router.go(-1)"
      >
        <i-icon icon="ic:baseline-arrow-back" width="20px" />
      </span>

      <div>
        <small class="font-light text-xs mb-0">Go Back</small>
        <h6 class="mb-0 font-semibold">
          {{ "Order Details" }}
        </h6>
      </div>
    </div>
    <h5 class="mt-3 text-sm" v-if="busy">Retrieving data</h5>
    <div class="mt-4" v-else>
      <div class="mb-5">
        <div class="flex justify-between">
          <div>
            <h6 class="font-semibold mb-0">
              {{ "Order ID:" }}
              <span class="uppercase">{{
                "#" + item.id.split("-").join("").slice(0, 5)
              }}</span>
            </h6>
            <h6 class="text-sm mb-0">
              {{ item.created_at | formatDate }}
            </h6>
            <span class="">
              <!-- <span class="status" :class="item.status"></span> -->
              <span class="text-[12px] status" :class="item.status">{{
                item.status
              }}</span>
            </span>
          </div>

          <div>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none text-dark100"
              v-if="item.status !== 'canceled' && item.status !== 'delivered'"
              text="Left align"
              dropleft
              no-caret
            >
              <template #button-content>
                <span class="text-dark100 font-bold">
                  <i-icon icon="iconamoon:menu-kebab-vertical-bold" />
                </span>
              </template>
              <template v-if="dropdownItem.length > 0">
                <b-dropdown-item
                  v-for="(dropdown, index) in dropdownItem"
                  :key="index"
                  class="text-capitalize"
                  @click="updateStatus(dropdown.value)"
                  >{{ dropdown.action.split("_").join(" ") }}</b-dropdown-item
                >
              </template>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-6">
        <div class="flex gap-3">
          <span class="bg-gray-300 p-2 rounded-full h-fit">
            <i-icon icon="ci:user-03" />
          </span>
          <div>
            <h6 class="mb-1 font-semibold">Customer</h6>
            <div class="flex flex-col">
              <!-- {{ item.user }} -->
              <span
                v-for="(value, name) in userData"
                :key="name"
                class="mb-0"
              >
                <span class="text-gray-500 text-sm capitalize"
                  >{{ name.split("_").join(" ") }}:
                </span>
                <span class="text-sm">{{ value }}</span>
              </span>
            </div>
          </div>
        </div>

        <div class="flex gap-3">
          <span class="bg-gray-300 p-2 rounded-full h-fit">
            <i-icon icon="iconamoon:location-light" />
          </span>
          <div>
            <h6 class="mb-1 font-semibold">Shipping Details</h6>
            <div class="flex flex-col">
              <!-- {{ item.user }} -->
              <span v-for="(value, name) in pickupData" :key="name" class="">
                <span class="text-gray-500 text-sm capitalize"
                  >{{ name }}:
                </span>
                <span class="text-sm capitalize">{{ value }}</span>
              </span>
            </div>
          </div>
        </div>

        <div class="flex gap-3">
          <span class="bg-gray-300 p-2 rounded-full h-fit">
            <i-icon icon="fluent:payment-32-regular" />
          </span>
          <div>
            <h6 class="mb-1 font-semibold">Payment Information</h6>
            <div class="flex flex-col gap-1">
              <!-- {{ item.user }} -->
              <span v-for="(value, name) in txnData" :key="name" class="">
                <span class="text-gray-500 text-sm capitalize"
                  >{{ name.split("_").join(" ") }}:
                </span>
                <span class="text-sm">{{ value }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h6 class="mb-1 font-semibold mt-6">Products</h6>
        <div>
          <table-component :items="products" :fields="fields" :paginate="false">
          </table-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import { pick } from "lodash";
export default {
  components: { TableComponent },
  data() {
    return {
      id: this.$route.params.id,
      item: {},
      products: [],
      fields: [
        {
          key: "name",
          label: "NAME",
        },
        {
          key: "price",
          label: "UNIT PRICE",
          formatter: (val) => {
            return Number(val).toLocaleString("en-US", {
              style: "currency",
              currency: "NGN",
            });
          },
        },
        {
          key: "pivot.quantity",
          label: "QUANTITY",
          formatter: (val) => {
            return Number(val).toLocaleString();
          },
        },
        {
          key: "totalOrderAmount",
          label: "TOTAL AMOUNT",
        },
      ],
      userDisplay: ["name", "email", "phone_no"],
      pickupDisplay: ["city", "state", "address"],
      txnDisplay: ["tx_ref", "description"],
      costDisplay: ["total_quantity", "total_amount"],
      busy: false
    };
  },
  methods: {
    getOrder() {
      this.busy = true;
      this.$http(`admin/find-order/${this.id}`)
        .then((res) => {
          let resPayload = res.data;
          console.log(resPayload);
          this.item = resPayload.order;
          this.products = resPayload.order.products;
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    updateStatus(value) {
      let payload = {
        status: value,
      };
      this.$http
        .post(`admin/update-order/${this.id}`, payload)
        .then((res) => {
          console.log(res);
          this.$toastify({
            text: `${res.data.message}`,
            className: "info",
            position: "center",
            style: {
              background: "#1e1e1e",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.getOrder();
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.$toastify({
            text: `${err.data.message}`,
            className: "info",
            position: "center",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.busy = false;
        });
    },
  },

  beforeMount() {
    this.getOrder();
  },

  computed: {
    userData() {
      let user = {
        name: this.item.user.first_name + " " + this.item.user.last_name,
        email: this.item.user.email,
        phone_no: this.item.user.phone_no,
      };
      return pick(user, this.userDisplay);
    },
    pickupData() {
      let address = {
        city: this.item.city,
        state: this.item.state,
        address: this.item.delivery_address,
      };
      return pick(address, this.pickupDisplay);
    },
    txnData() {
      return pick(this.item.payment, this.txnDisplay);
    },
    costDetails() {
      let data = {
        total_quantity: Number(this.item.total_quantity).toLocaleString(),
        total_amount: Number(this.item.total_amount).toLocaleString("en-US", {
          style: "currency",
          currency: "NGN",
        }),
      };
      return pick(data, this.costDisplay);
    },

    dropdownItem() {
      // var filteredActions = [];
      var actions = [
      {
          value: "canceled",
          action: "reject",
          status: "pending",
        },
      {
          value: "confirmed",
          action: "confirm",
          status: "pending",
        },
        {
          value: "in-progress",
          action: "Package",
          status: "confirmed",
        },
        {
          value: "completed",
          action: "complete",
          status: "in-progress",
        },
        {
          value: "delivered",
          action: "delivered",
          status: "completed",
        },
      ];
      // if (this.item.status === "pending") {
      //   filteredActions = actions.filter((item) => item.status === "pending");
      // }
      // if (this.item.status === "confirmed") {
      //   filteredActions = actions.filter((item) => item.status === "confirmed");
      // }
      // if (this.item.status === "in-progress") {
      //   filteredActions = actions.filter(
      //     (item) => item.status === "in-progress"
      //   );
      // }
      // if (this.item.status === "completed") {
      //   filteredActions = actions.filter((item) => item.status === "completed");
      // }
      // return filteredActions;
      return actions
    },
  },
};
</script>

<style></style>
