<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="30%"
    >
      <div class="mt-6">
        <div>
          <validation-observer v-slot="{ invalid, handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <div>
                <validation-provider
                  name="Country"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="name">Select State</label>
                  <!-- <country-select
                      class="mt-2 w-100"
                      :countryName="true"
                      v-model="country"
                      :country="country"
                      topCountry="NG"
                    /> -->
                  <select v-model="dataObj.name">
                    <option value="" selected disabled>Select State</option>
                    <option
                      v-for="(item, idx) in states"
                      :key="idx"
                      :value="item"
                    >
                      {{ item === "AkwaIbom" ? "Akwa Ibom" : item }}
                    </option>
                  </select>
                  <span
                    class="text-xs block text-red-600"
                    v-if="errors"
                    >{{ errors[0] }}</span
                  >
                </validation-provider>
              </div>

              <div class="mt-3">
                <validation-provider
                  name="State"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="name">Select LGA</label>
                  <select v-model="dataObj.lga">
                    <option value="" selected disabled>Select LGA</option>
                    <option
                      v-for="(item, idx) in lgas"
                      :key="idx"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <!-- <region-select
                      class="w-100"
                      v-model="dataObj.name"
                      :country="country"
                      :region="dataObj.name"
                      :countryName="true"
                      :regionName="true"
                    /> -->
                  <span
                    class="text-xs block text-red-600"
                    v-if="errors"
                    >{{ errors[0] }}</span
                  >
                </validation-provider>
              </div>

              <!-- <div class="mt-3">
                  <validation-provider
                    name="Local Government Area"
                    rules="required"
                    v-slot="{ dirty, invalid, errors }"
                  >
                    <label for="name">Local Government Area</label>
                    <div
                      class="input-field"
                      :class="{
                        error: dirty && invalid,
                      }"
                    >
                      <input
                        type="text"
                        name="text"
                        id="name"
                        v-model="dataObj.lga"
                        :readonly="viewMode"
                        :disabled="viewMode"
                        placeholder="Local Government Area"
                      />
                    </div>
                    <span
                      class="text-xs block text-red-600"
                      v-if="errors"
                      >{{ errors[0] }}</span
                    >
                    <span
                      class="mb-5 block text-xs text-red-600"
                      v-for="error in validationErrors.lga"
                      :key="error"
                      >{{ error }}</span
                    >
                  </validation-provider>
                </div> -->

              <div class="mt-3">
                <validation-provider
                  name="Name"
                  rules="required|numeric"
                  v-slot="{ dirty, invalid, errors }"
                >
                  <label for="name">Price</label>
                  <div
                    class="input-field"
                    :class="{
                      error: dirty && invalid,
                    }"
                  >
                    <input
                      type="text"
                      name="text"
                      id="name"
                      v-model="dataObj.price"
                      :readonly="viewMode"
                      :disabled="viewMode"
                      placeholder="Name"
                    />
                  </div>
                  <span
                    class="text-xs block text-red-600"
                    v-if="errors"
                    >{{ errors[0] }}</span
                  >
                  <span
                    class="mb-5 block text-xs text-red-600"
                    v-for="error in validationErrors.name"
                    :key="error"
                    >{{ error }}</span
                  >
                </validation-provider>
              </div>

              <div class="mt-4" v-if="!viewMode">
                <button
                  class="warehauze-btn warehauze-primary w-100"
                  v-bind:disabled="invalid"
                  :class="{ 'bg-gray400': invalid, 'bg-gray400': busy }"
                >
                  <span>{{ editMode ? "Update" : "Create" }}</span>
                </button>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },

    data: {
      type: Object,
      default: () => {},
    },

    item: {
      type: Object,
      default: () => {},
    },

    editMode: {
      type: Boolean,
      default: false,
    },

    viewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      states: [],
      lgas: [],
      dataObj: {
        name: "",
        price: "",
        lga: "",
      },
      country: "",
      busy: false,
      validationErrors: {},
      dialogVisible: false,
    };
  },

  methods: {
    onSubmit() {
      this.busy = true;
      var url = this.editMode
        ? `admin/shipping-zone/update/${this.item.id}`
        : "/admin/create-shipping-zone";

      let payload = {
        shipping_class_id: this.item.id,
        name:
          this.dataObj.name === "AkwaIbom" ? "Akwa Ibom" : this.dataObj.name,
        price: this.dataObj.price,
        lga: this.dataObj.lga,
      };
      this.$http
        .post(`${url}`, payload)
        .then((res) => {
          console.log(res.data);
          this.busy = false;
          this.$toastify({
            text: this.editMode ? `${res.data.message}` : `${res.data.message}`,
            className: "info",
            position: "center",
            style: {
              background: "#1e1e1e",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.dataObj.name = "";
          this.$emit("done");
        })
        .catch((err) => {
          this.busy = false;
          this.validationErrors = err.data.errors;
          console.log(err.data.errors);
          this.dataObj.name = "";
          this.$toastify({
            text: `Error`,
            className: "info",
            position: "center",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          console.log(err);
        });
    },

    handleClose() {
      this.validationErrors = {};
      this.$emit("toggle");
    },

    async getStates() {
      try {
        const req = await this.$http.get(
          "https://nga-states-lga.onrender.com/fetch"
        );
        console.log(req.data);
        this.states = req.data;
      } catch (res) {
        console.log(res);
      }
    },

    async getLGA() {
      if (this.dataObj.name !== "" || null) {
        try {
          const req = await this.$http.get(
            `https://nga-states-lga.onrender.com/?state=${this.dataObj.name}`
          );
          console.log(req.data);
          this.lgas = req.data;
        } catch (res) {
          console.log(res);
        }
      }
    },
  },

  beforeMount() {
    this.getStates();
  },

  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
      immediate: true,
    },

    item: {
      handler(val) {
        if (val) {
          this.dataObj = {
            name: val.name,
            price: val.price,
            lga: val.lga,
          };
        }
      },
      immediate: true,
    },

    "dataObj.name": {
      handler: debounce(function () {
        this.getLGA();
      }, 500),
    },
  },
};
</script>

<style></style>
