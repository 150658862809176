// import Vue from 'vue';
// import $http from '@/config/axios'
// import Axios from '@/plugins/axios'
import createPersistedState from "vuex-persistedstate";

// import router from '@/router'

// import "toastify-js/src/toastify.css"
// import Toastify from 'toastify-js'

// Vue.prototype.$http = http

const getDefaultState = () => {
  return {
    zones: {},
    methods: {},
  };
};

export default {
  namespaced: true,
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    getMethods: (state) => {
      return state.methods;
    },
    getZones: (state) => {
      return state.zones;
    },
  },

  mutations: {
    SET_SHIPPING_ZONES: (state, payload) => {
      state.zones = payload;
    },
    SET_SHIPPING_METHODS: (state, payload) => {
      state.methods = payload;
    },
  },

  actions: {
    //  Get Shipping Zones
    async setShippingZones({ commit }, payload) {
      commit("SET_SHIPPING_ZONES", payload);
    },

    // Shipping Methods
    async setShippingMethods({ commit }, payload) {
      commit("SET_SHIPPING_METHODS", payload);
    },
  },
};
