<template>
  <div>
    <div>
      <div>
        <div class="mt-3">
          <label for="" class="mb-2">Product Description</label>
          <div v-html="description">

          </div>
          <!-- <textarea
          class="border border-gray-400 w-full rounded-lg"
            name=""
            id=""
            v-model="description"
            cols="30"
            rows="8"
          ></textarea> -->
          <!-- <vue-editor
            disabled
            v-model="product.description"
            :editor-toolbar="customToolbar"
          ></vue-editor> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { VueEditor } from "vue2-editor";
export default {
  props: {
    product: {
      type: Object,
    },
  },
  components: {
    // VueEditor,
  },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ script: "sub" }, { script: "super" }],
      ],
      description: "",
    };
  },
  computed: {},

  watch: {
    product: {
      handler(val) {
        this.description = val.description;
      },
      immediate: true,
    },
  },
};
</script>
