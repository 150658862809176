<template>
  <div>
    <div class="flex justify-end mb-4">
      <filter-tabs :tabs="tabs" @filter-changed="filter($event)" />
    </div>
    <div>
      <table-component
        :items="items"
        :fields="fields"
        :disableDeleteAction="true"
        :disableEditAction="true"
        :busy="busy"
        @view="view"
        :meta="meta"
        @next="filter($event)"
      />
    </div>

    <!-- View Receipt -->
    <view-receipts
      :visible="dialogVisible"
      @toggle="close"
      :date="item.created_at"
      title="Transaction Details"
      :displayData="displayData"
      :data="item"
      :display-key="[
        'customer_name',
        'amount',
        'reference',
        'transaction_type',
        'details',
      ]"
    />
  </div>
</template>

<script>
import FilterTabs from "@/components/Utils/FilterTabs.vue";
import TableComponent from "@/components/TableComponent.vue";
import ViewReceipts from "@/components/Finance/ViewReceipts.vue";
export default {
  components: { FilterTabs, TableComponent, ViewReceipts },
  data() {
    return {
      tabs: [
        {
          title: "All",
          label: "",
        },
        {
          title: "Daily",
          label: "today",
        },
        {
          title: "Weekly",
          label: "7-days",
        },
        {
          title: "Monthly",
          label: "1-month",
        },
        {
          title: "Last Year",
          label: "1-year",
        },
      ],
      items: [],
      fields: [
        {
          key: "beneficiary",
          label: "Beneficiary",
        },
        {
          key: "amount",
          label: "Amount",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "";
          },
        },
        {
          key: "transaction_type",
          label: "Transaction Type",
        },
        // {
        //   key: "status",
        //   label: "Status",
        // },
        {
          key: "created_at",
          label: "Date Processed",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      busy: false,
      dialogVisible: false,
      displayData: {
        customer_name: "",
        amount: "",
        reference: "",
        transaction_type: "",
        details: "",
      },
      item: {},
      meta: {},
    };
  },

  methods: {
    filter(e, page = 1) {
      this.busy = true;
      this.$http
        .get(`admin/all-transactions?time=${e}&page=${page}`)
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload.data;
          console.log(resPayload);
          this.meta = resPayload
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    view(e) {
      console.log(e, "kkkk");
      this.$http
        .get(`admin/show-transaction/${e.id}`)
        .then((res) => {
          let resPayload = res.data;
          console.log(resPayload, "ommmo");
          this.item = resPayload.transaction;
          this.dialogVisible = !this.dialogVisible;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    close() {
      this.dialogVisible = !this.dialogVisible;
    },
  },

  beforeMount() {
    let value = "";
    this.filter(value);
  },

  watch: {
    item: {
      handler(val) {
        if (val.user) {
          this.displayData = {
            customer_name: val.beneficiary,
            details: val.details,
            amount: `${Number(val.amount).toLocaleString("en-US", {
              style: "currency",
              currency: "NGN",
            })}`,
            reference: val.ref_no,
            transaction_type: val.transaction_type,
          };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
