var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.busy || _vm.isLoading)?_c('div',{staticClass:"flex flex-col gap-3 mt-2"},[_c('b-skeleton',{attrs:{"width":"100%","height":"30px"}}),_c('div',{staticClass:"px-4"},[_c('b-skeleton',{attrs:{"width":"100%","height":"30px"}}),_c('span',{staticClass:"mt-2"},_vm._l((4),function(item){return _c('b-skeleton',{key:item,attrs:{"width":"100%","height":"20px"}})}),1)],1)],1):_c('div',{staticClass:"mt-2"},_vm._l((_vm.roleInfo.perms),function(item,idx){return _c('div',{key:idx},[_c('span',{staticClass:"bg-gray-100 px-4 py-2 font-semibold text-sm block"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"px-4 py-2"},_vm._l((item.sub_modules),function(it,idx){return _c('span',{key:idx,staticClass:"flex flex-col gap-2 mt-2"},[_c('span',{staticClass:"bg-gray-100 px-2 py-2 text-sm block"},[_vm._v(" "+_vm._s(it.name)+" ")]),_vm._l((it.permissions),function(value,idx){return _c('span',{key:idx,staticClass:"block text-sm capitalize flex items-center justify-between"},[_c('span',{staticClass:"flex gap-[10px]"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(value.access),expression:"value.access"}],staticClass:"w-fit",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(value.access)?_vm._i(value.access,null)>-1:(value.access)},on:{"change":function($event){var $$a=value.access,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(value, "access", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(value, "access", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(value, "access", $$c)}}}}),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(value.name.split("_").join(" ")))])]),_c('span',{staticClass:"flex gap-2 items-center"},[_c('button',{staticClass:"warehauze-btn py-2 px-2 flex justify-center font-medium items-center gap-1",class:[
                  !value.access
                    ? 'bg-green-600 text-white'
                    : _vm.isAssignLoading === value.name
                    ? 'bg-gray-400'
                    : 'warehauze-primary',
                ],attrs:{"disabled":_vm.isAssignLoading === value.name},on:{"click":function($event){return _vm.assignFunction(value)}}},[(_vm.isAssignLoading === value.name)?_c('i-icon',{staticClass:"text-white text-[14px]",attrs:{"icon":"eos-icons:loading"}}):_vm._e(),_vm._v(" "+_vm._s(value.access ? "Remove Access" : "Grant Access")+" ")],1)])])})],2)}),0)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }