<template>
    <div class="pagination flex justify-between w-full items-center bg-gray-100 py-3 px-5">
      <div>
        <span class="text-sm font-medium w-full"> {{  `${meta.from} - ${meta.to} of ${meta.total} items`  }} </span>
      </div>
      <div class="flex gap-3">
        <div class="flex items-center gap-3">
          <button
            class="bg-white rounded-[4px] p-[5px]"
            :disabled="meta.current_page === 1"
            @click="goToPage(meta.current_page - 1)"
          >
            <i-icon icon="ph:caret-left" />
          </button>
  
          <button
            class="text-sm page-btn"
            v-for="page in pages"
            :key="page"
            :class="{ active: meta.current_page === page }"
            @click="goToPage(page)"
          >
            {{ page }}
          </button>
  
          <button
            class="bg-white rounded-[4px] p-[5px]"
            :disabled="meta.current_page === meta.last_page"
            @click="goToPage(meta.current_page + 1)"
          >
            <i-icon icon="ph:caret-right" />
          </button>
        </div>
        <div class="flex gap-2 items-center">
          <span class="text-sm font-medium">Go to</span>
          <input
            type="number"
            v-model="pageNumber"
            class="rounded-[4px] w-[50px] px-[6px] py-[4px] text-[13px]"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      meta: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        pageNumber: ''
      }
    },
    computed: {
      pages() {
        const pages = []
        const range = 2
        const start = Math.max(2, this.meta.current_page - range)
        const end = Math.min(this.meta.last_page - 1, this.meta.current_page + range)
  
        pages.push(1)
  
        if (start > 2) {
          pages.push('...')
        }
  
        for (let i = start; i <= end; i++) {
          pages.push(i)
        }
  
        if (end < this.meta.last_page - 1) {
          pages.push('...')
        }
        if (this.meta.last_page > 1) {
          pages.push(this.meta.last_page)
        }
        return pages
      }
    },
    methods: {
      goToPage(page) {
        if (page !== '...' && page >= 1 && page <= this.meta.last_page) {
          this.$emit('next', page)
        }
      }
    },
  
    watch: {
      pageNumber: {
        handler(val) {
          if (val) {
            this.goToPage(this.pageNumber)
          }
        },
        immediate: true
      }
    }
  }
  </script>
  
  <style>
  .pagination {
    list-style-type: none;
  }
  
  .pagination button.page-btn {
    /* margin: 0 5px; */
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .pagination button[disabled] {
    cursor: not-allowed;
  }
  
  .pagination button.active {
    color: #fff;
    background: var(--primary-color);
    border-radius: 4px;
  }
  </style>
  